import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UserData } from 'build/openapi';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-base-question-number',
  templateUrl: './base-question-number.component.html',
  styleUrls: ['./base-question-number.component.css']
})
export class BaseQuestionNumberComponent implements OnInit {
  @Input() key: string;
  @Input() question: string;
  @Input() defaultValue: number = 0;

  @ViewChild("Input") inputField: ElementRef;

  private static readonly STORAGE_KEY_PREFIX = "QUESTION_NUMBER_";
  private static readonly STORAGE_KEY_PREFIX_QUESTION = "QUESTION_";
  private static readonly STORAGE_KEY_PREFIX_SELECTED_VALUE = "VALUE_";

  selectedValue: number = null;
  inputValue: string;

  feedbackValues: string[];

  constructor(private appService: AppService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.selectedValue = this.defaultValue;
    this.inputValue = this.selectedValue.toString();
    let data = this.appService.getUserData(BaseQuestionNumberComponent.STORAGE_KEY_PREFIX + BaseQuestionNumberComponent.STORAGE_KEY_PREFIX_QUESTION + this.key);
    if (data) {
      var value = data.value;
      if (value) {
        this.onSelection(value);
      }
    }
    else {
      this.updateUserData(this.defaultValue + '');
    }
  }

  routeAnimationDone() {
  }

  onSelection(newValue: string) {
    if (this.selectedValue >= 0) {
      this.selectedValueChanged(newValue);
    }
  }

  selectedValueChanged(newValue: string) {
    let newNum = Number(newValue);
    if (newNum >= 0) {
      this.inputValue = newValue;
    }
    this.selectedValue = newNum;
    this.updateUserData(newValue);
  }

  updateUserData(value: string) {
    this.appService.sendUserData(BaseQuestionNumberComponent.STORAGE_KEY_PREFIX + BaseQuestionNumberComponent.STORAGE_KEY_PREFIX_QUESTION + this.key, value);
  }

  isPrevDisabled(): boolean {
    return false;
  }

  isNextDisabled(): boolean {
    return false;
  }

}
