import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, Input, OnInit, ViewChild} from '@angular/core';
import { AnimationCallbacks } from 'src/app/animationCallbacks';
import { Options } from 'src/app/model/options';
import { AppService } from 'src/app/services/app.service';
import { RouteService } from 'src/app/services/route.service';

@Component({
  selector: 'app-base-selection',
  templateUrl: './base-selection.component.html',
  styleUrls: ['./base-selection.component.css']
})
export class BaseSelectionComponent implements OnInit, AfterViewInit, AnimationCallbacks {
  @Input() key: string;
  @Input() question: string;
  @Input() instruction: string;
  @Input() defaultValue: number = 0;
  @Input() defaultOption: number;
  @Input() options: string[] | Options;
  @Input() textField: number;
  @Input() type: string;
  @Input() componentName: string;
  @Input() info: string;
  @Input() mainQuestion?: string;
  @Input() deactiveFooter?: boolean;
  @Input() deactiveQuestion?: boolean;


  @ViewChild('scomp') selectionComponent;

  viewInitDone = -1;

  constructor(private route: RouteService, public app: AppService, private resolver: ComponentFactoryResolver, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.route.className = this.componentName;
  }

  ngAfterViewInit(): void {
    this.viewInitDone = 0;
  }

  isNumberComponent(): boolean {
    return this.type === 'number';
  }

  isButtonComponent(): boolean {
    return this.type === 'button';
  }

  isServicesComponent(): boolean {
    return this.type === 'services';
  }

  isOtherComponent(): boolean {
    return !this.isNumberComponent() && !this.isButtonComponent() && !this.isServicesComponent();
  }

  isPrevDisabled(): boolean {
    if(this.viewInitDone >= 1) { // See comment at isNextDisabled()
      if(this.selectionComponent.isPrevDisabled) {
        return this.selectionComponent.isPrevDisabled();
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  isNextDisabled(): boolean {
    if(this.viewInitDone >= 0) {
      this.viewInitDone += 1;
    }
    if(this.viewInitDone >= 2) { // This is all super hacky, but I had to find a way (and couldn't find any better) to avoid the "ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'disabled: true'. Current value: 'disabled: false'." error
      if(this.selectionComponent.isNextDisabled) {
        return this.selectionComponent.isNextDisabled();
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  routeAnimationDone() {
    this.route.routeAnimationDone();
    this.selectionComponent.routeAnimationDone();
  }

  navigate_prev(): void {
    this.route.routePrevName();
  }

  navigate_next(): void {
    this.route.routeNextName();
  }
}
