import { Component, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppService } from './services/app.service';
import { Title } from '@angular/platform-browser';
import { AuthService, AuthState } from './auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AnimationCallbacks } from './animationCallbacks';
import { fadeAnimation } from './animations';
import { FeedBackService } from './services/feedback.service';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    fadeAnimation,
  ]
})
export class AppComponent {

  title: string;
  loggedIn: boolean;
  loggedInUserInfo!: string
  routerOutlet: RouterOutlet;

  @ViewChild('duplicateUUIDAlert')
  public readonly duplicateUUIDAlert!: SwalComponent;

  constructor(
    private dialog: MatDialog,
    private titleService: Title,
    private appService: AppService,
    private authService: AuthService,
    private feedBackService: FeedBackService,
    public swalTargets: SwalPortalTargets
  ) {
    this.title = 'GrundschutzPlus Aktivator';
    this.titleService.setTitle(this.title);
    this.authService.authState$.subscribe(authState => {
      this.handleAuthState(authState);
    });
  }

  ngAfterViewInit(): void {
    if (this.duplicateUUIDAlert && this.appService.checkDuplicateUUID()) {
      this.duplicateUUIDAlert.update({
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        customClass: {
          popup: 'duplicate-uuid-dialog',
          confirmButton: 'button-bordered mat-button button-confirm button-load-from-account',
          cancelButton: 'button-bordered mat-button button-cancel button-replace-in-account',
          title: 'title-duplicate-uuid-alert'
        },
        allowOutsideClick: false
      } as SweetAlertOptions)
      this.duplicateUUIDAlert.fire().then((result) => {
        if (result.isConfirmed) {
          this.appService.removeLocalUUID();
          this.appService.nivigateToLastVisitedStep();
        }
        else if (result.isDismissed) {
          this.appService.sendLocalUUID().subscribe(() => {
            this.appService.nivigateToLastVisitedStepByUUID(this.appService.removeLocalUUID());
          });
        }
        const currentDate = new Date();
        const year = currentDate.getFullYear().toString().padStart(4, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        const currentDateAsString = `${year}-${month}-${day}`;
        this.appService.sendUserData("LASTLOGIN",  currentDateAsString);
      });
    }
  }

  private handleAuthState(authState: AuthState) {
    this.loggedIn = authState.loggedIn;
    if (this.loggedIn) {
      this.loggedInUserInfo = `Hallo, ${authState.profile.firstName} ${authState.profile.lastName}`;
    }
  }

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  lastLogin(): Date {
    const tmp = this.appService.getUserData("LASTLOGIN");
    return tmp ? new Date(tmp.value) : undefined;
  }

  checkIfLastLoginBeforChanges(): boolean {
    const lastChanges = new Date('2023-08-01');
    const lastLogin = this.lastLogin();
    if (lastLogin) {return lastLogin > lastChanges ? true : false;}
    return false;
  }

  toHome() {
    this.appService.navigateToHome();
  }

  toGlossary() {
    this.appService.navigateToGlossary();
  }

  toLogin() {
    this.authService.loginAfterRedirect('/enter');
  }

  toLogout() {
    this.authService.logout();
  }

  prepareRoute(outlet: RouterOutlet) {
    this.routerOutlet = outlet;
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  openFeedbackDialog(): void {
    this.feedBackService.openFeedbackDialog(this.dialog);
  }

  routeAnimationDone() {
    if (this.routerOutlet.isActivated) {
      let component = this.routerOutlet.component;
      if ((component as AnimationCallbacks).routeAnimationDone) {
        (component as AnimationCallbacks).routeAnimationDone();
      }
    }
  }
}
