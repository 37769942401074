<div [ngClass]="{'item-container-relevant': !notRelevant, 'item-container-notRelevant': notRelevant}">

  <div class="checkbox-container">
    <mat-checkbox *ngIf="!isLogged" [(ngModel)]="this.massnahme.data.activeCheckbox" [disabled]="notRelevant" (change)="onCheckboxChange($event.checked)"></mat-checkbox>
    <mat-checkbox *ngIf="isLogged" [(ngModel)]="this.massnahme.data.activeCheckbox" [disabled]="notRelevant" (change)="onCheckboxChange($event.checked)" [matMenuTriggerFor]="menu"></mat-checkbox>
  </div>

  <div class="massnahme-container">

      <div class="text-container" innerHTML={{massnahme.data.textLong}}></div>
    	<div class="icon-button-container">
        <div class="icon-container">
          <button mat-icon-button class="icon-button" [matTooltip]="tooltipRoles" [matTooltipClass]="'checklist-tooltip'"><mat-icon svgIcon="logged-in"></mat-icon></button>
          <button mat-icon-button class="icon-button" matTooltip="{{tooltipThreats}}" [matTooltipClass]="'checklist-tooltip'"><mat-icon svgIcon="threat"></mat-icon></button>
          <button mat-icon-button class="icon-button" matTooltip="{{tooltipCriticality}}" [matTooltipClass]="'checklist-tooltip'"><mat-icon  svgIcon={{criticality}} style="transform: scale(2.5);"></mat-icon></button>
          <button mat-icon-button class="icon-button" matTooltip="{{tooltipEffort}}" [matTooltipClass]="'checklist-tooltip'"><mat-icon svgIcon={{effort}} style="transform: scale(2.5);"></mat-icon></button>
          <button mat-icon-button class="icon-button" matTooltip="{{tooltipCategory}}" [matTooltipClass]="'checklist-tooltip'"><mat-icon svgIcon="category" ></mat-icon></button>
          <button mat-icon-button *ngIf="spec != ''" class="icon-button" [matTooltip]="spec" [matTooltipClass]="'checklist-tooltip'"><mat-icon svgIcon="din" style="transform: scale(2.5);"></mat-icon></button>
        </div>

        <div class="button-container">
          <button mat-icon-button *ngIf="isLogged" (click)="get_comment('comment')" class="icon-button" matTooltip="Kommentar hinzufügen oder bearbeiten" [matTooltipClass]="'checklist-tooltip'"><mat-icon *ngIf="massnahme.data.comment == 'empty'" svgIcon="comment-no" style="transform: scale(2);"></mat-icon><mat-icon *ngIf="massnahme.data.comment != 'empty'" svgIcon="comment" style="transform: scale(2);"></mat-icon></button>
          <mat-slide-toggle *ngIf="isLogged" color="primary" [(ngModel)]="notRelevant" (change)="get_comment('relevant')" class="toggle-button" matTooltip="Diese Maßnahme als nicht relevant&#10;kennzeichnen" [matTooltipClass]="'checklist-tooltip'"></mat-slide-toggle>
        </div>
      </div>


      <mat-divider></mat-divider>

        <div class="checklist-template" *ngIf="massnahme.data.template" innerHTML="Umsetzungsdokumente: {{massnahme.data.template}}"></div>
        <div class="checklist-item-id">ID: {{massnahme.data.key}}</div>
        <div class="checklist-source" *ngIf="massnahme.data.source" innerHTML="Quellen: {{massnahme.data.source}}"></div>

  </div>
</div>


<mat-menu #menu="matMenu">
  <button (mouseleave)="mouseLeave()" mat-menu-item (click) = "get_comment_afer_checkbox_change()">{{commentText}}</button>
</mat-menu>
