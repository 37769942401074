import { Component, OnInit, ViewChild } from '@angular/core';
import { AnimationCallbacks } from '../../animationCallbacks';
import { BaseQuestionNumberComponent } from '../shared/base-question-number/base-question-number.component';
import { BaseQuestionButtonsComponent } from '../shared/base-question-buttons/base-question-buttons.component';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css']
})
export class BusinessComponent implements OnInit, AnimationCallbacks{
  @ViewChild('qcomp') qcompButtonType: BaseQuestionButtonsComponent;
  @ViewChild('qcomp') qcompButtonCount: BaseQuestionButtonsComponent;
  @ViewChild('qcomp') qcompNumber: BaseQuestionNumberComponent;

  public readonly QUESTION = "Unternehmen";
  public readonly STORAGE_KEY_COUNT_PEOPLE = "EMPLOYEE_COUNT";
  public readonly QUESTION_COUNT_PEOPLE = "Wie viele Mitarbeitende arbeiten in Ihrem Unternehmen regelmäßig mit IT-Systemen?";
  public readonly OPTIONS_COUNT_PEOPLE = ['1 - 10', '11 - 50', '51 - 200', 'mehr als 200', 'weiß nicht'];

  public readonly STORAGE_KEY_COUNT_SITE = "SITE_COUNT";
  public readonly QUESTION_COUNT_SITE = "Wie viele Standorte hat Ihr Unternehmen?";
  public readonly DEFAULT_VALUE_COUNT_SITE = 1;

  public readonly STORAGE_KEY_TYPE = "TYPE_OF_BUSINESS";
  public readonly QUESTION_TYPE = "In welcher Branche ist Ihr Unternehmen tätig?";
  public readonly OPTIONS_TYPE = ['Produktion', 'Handel', 'Dienstleistungen', 'Handwerk', 'Sonstiges', 'weiß nicht'];
  public readonly TEXTFIELD_TYPE = 4;



  constructor(private app: AppService) {}

  ngOnInit(): void {
  }


  routeAnimationDone() {
    this.qcompButtonType.routeAnimationDone();
    this.qcompButtonCount.routeAnimationDone();
    this.qcompNumber.routeAnimationDone();
  }

  getComponentName(): string {
    return "BusinessComponent";
  }
}
