import { Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import { FeedBackService } from 'src/app/services/feedback.service';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.css']
})
export class FeedbackDialogComponent implements OnInit {

  private readonly matDialogRef: MatDialogRef<FeedbackDialogComponent>;

  feedback: string;

  constructor(
    matDialogRef: MatDialogRef<FeedbackDialogComponent>,
    private feedbackService: FeedBackService) {
    this.matDialogRef = matDialogRef;
  }

  ngOnInit() {
    this.matDialogRef.updateSize('25em', '17em');
    this.matDialogRef.updatePosition({ right: '5rem', bottom: `5rem` });
  }

  onSend(){
    this.feedbackService.sendFeedback(this.feedback);
    this.matDialogRef.close(null);
  }

  cancel(): void {
    this.matDialogRef.close(null);
  }
}
