<div class="container">
    <app-base-question style="width: 100%;"  *ngIf="!mainQuestion && !deactiveQuestion" [question]="question" [instruction]="instruction" [info]="info"></app-base-question>
    <app-base-question *ngIf="mainQuestion" [question]="mainQuestion" [instruction]="instruction" [info]="info"></app-base-question>
    <div class="content">
        <ng-container *ngIf="isNumberComponent()">
            <app-base-question-number class="scomp" #scomp [key]="key" [question]="question"
                [defaultValue]="defaultValue"></app-base-question-number>
        </ng-container>
        <ng-container *ngIf="isButtonComponent()" >
            <app-base-question-buttons class="scomp" #scomp [key]="key" [question]="question" [options]="options"
                [textField]="textField"></app-base-question-buttons>
        </ng-container>
        <ng-container *ngIf="isServicesComponent()">
            <app-services-view class="scomp" #scomp [key]="key" [question]="question" [instruction]="instruction"
                [options]="options" [defaultOption]="defaultOption"></app-services-view>
        </ng-container>
    </div>

</div>
<app-footer *ngIf="!deactiveFooter"></app-footer>
