import { HttpEvent, HttpHandler, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CSRFHttpInterceptorService {

  constructor(private xrsfTokenExtractor: HttpXsrfTokenExtractor) {
    
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.apiUrl)) {
      let token = this.xrsfTokenExtractor.getToken();
      if(token != null) {
        req = req.clone({
          setHeaders: {
            'X-XSRF-TOKEN': `${token}`
          }
        });
      }
    }
    return next.handle(req);
  }

}
