import { ApiModule } from 'build/openapi/api.module';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';

import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog'
/* import { MatTooltipModule } from '@angular/material/tooltip'; */
import { MatLegacyTooltipModule as MatTooltipModule, MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS, MatLegacyTooltipDefaultOptions} from '@angular/material/legacy-tooltip' ;
import { MatBadgeModule } from '@angular/material/badge';
/* import { MatSlideToggleModule } from '@angular/material/slide-toggle'; */
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
// import { MatTabsModule } from '@angular/material/tabs';
import { MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MaterialIconsModule } from './material-icons/material-icons.module';
import { MaterialModule } from './material/material.module';

import { SelectServicesComponent } from './components/services/select-services/select-services.component';
import { ServiceViewComponent } from './components/services/service-view/service-view.component';
import { ServiceClassViewComponent } from './components/services/service-class-view/service-class-view.component';
import { ServicesViewComponent } from './components/services/services-view/services-view.component';
import { DialogBausteinComponent } from './components/bausteine/dialog-baustein/dialog-baustein.component';

import { BASE_PATH } from '../../build/openapi';
import { environment } from '../environments/environment';
import { BaseQuestionButtonsComponent } from './components/shared/base-question-buttons/base-question-buttons.component';
import { BaseQuestionNumberComponent } from './components/shared/base-question-number/base-question-number.component';
import { NumericDirective } from './directive/numeric.directive';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { EnterAfterLoginComponent } from './components/enter-after-login/enter-after-login.component';
import { SelectServiceProvidersComponent } from './components/services/select-service-providers/select-service-providers.component';
import { SelectServiceCriticalityComponent } from './components/services/select-service-criticality/select-service-criticality.component';
import { BaseSelectionComponent } from './components/shared/base-selection/base-selection.component';
import { BaseFeedbackComponent } from './components/shared/base-feedback/base-feedback.component';
import { SelectServiceErpComponent } from './components/services/select-service-erp/select-service-erp.component';
import { ExitPageComponent } from './components/exit-page/exit-page.component';
import { BausteineComponent } from './components/bausteine/bausteine.component';
import { BausteinComponent } from './components/bausteine/baustein/baustein.component';
import { FeedbackDialogComponent } from './components/feedback-dialog/feedback-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { ChecklistComponent } from './components/checklist/checklist.component';
import { ChecklistControlComponent } from './components/checklist/checklist-control/checklist-control.component';
import { ChecklistMassnahmenComponent } from './components/checklist/checklist-massnahmen/checklist-massnahmen.component';
import { ChecklistItemComponent } from './components/checklist/checklist-item/checklist-item.component';
import { BaseQuestionComponent } from './components/shared/base-question/base-question.component';
import { DialogInstructionComponent } from './components/shared/dialog-instruction/dialog-instruction.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { MassnahmenGuard } from './guards/massnahmen.guard';
import { AuthModule } from './auth/auth.module';
import { AppGuard } from './guards/app.guard';
import { BausteineGuard } from './guards/bausteine.guard';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CSRFHttpInterceptorService } from './services/csrfhttp-interceptor.service';

import { FormatStringPipe } from './components/bausteine/dialog-baustein/dialog-baustein.pipe';
import { BusinessComponent } from './components/business/business.component';
import { FeedbackReaderComponent } from './components/feedback-reader/feedback-reader.component';
import { ChecklistCommentComponent } from './components/checklist/checklist-comment/checklist-comment.component';
import { NgChartsModule } from 'ng2-charts';
import { GlossaryComponent } from './components/glossary/glossary.component';

export const myCustomTooltipDefaults: MatLegacyTooltipDefaultOptions = {
  showDelay: 500,
  hideDelay: 0,
  touchendHideDelay: 0,
  disableTooltipInteractivity:true
};

@NgModule({
  declarations: [
    AppComponent,
    SelectServicesComponent,
    ServiceViewComponent,
    ServiceClassViewComponent,
    ServicesViewComponent,
    DialogBausteinComponent,
    BaseQuestionButtonsComponent,
    BaseQuestionNumberComponent,
    NumericDirective,
    SelectServiceProvidersComponent,
    SelectServiceCriticalityComponent,
    BaseSelectionComponent,
    BaseFeedbackComponent,
    SelectServiceErpComponent,
    LandingPageComponent,
    ExitPageComponent,
    BausteineComponent,
    BausteinComponent,
    FeedbackDialogComponent,
    FooterComponent,
    ChecklistComponent,
    ChecklistControlComponent,
    ChecklistMassnahmenComponent,
    ChecklistItemComponent,
    BaseQuestionComponent,
    DialogInstructionComponent,
    StepperComponent,
    EnterAfterLoginComponent,
    FormatStringPipe,
    BusinessComponent,
    FeedbackReaderComponent,
    ChecklistCommentComponent,
    GlossaryComponent
  ],
  imports: [
    ApiModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MaterialModule,
    MaterialIconsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMenuModule,
    MatDialogModule,
    MatTableModule,
    MatTooltipModule,
    MatBadgeModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatRippleModule,
    MatCardModule,
    MatTabsModule,
    MatProgressBarModule,
    AuthModule,
    NgChartsModule,
    [SweetAlert2Module.forRoot()],
  ],
  providers: [
    AppGuard,
    BausteineGuard,
    MassnahmenGuard,
    { provide: BASE_PATH, useValue: environment.apiUrl },
    { provide: HTTP_INTERCEPTORS, useClass: CSRFHttpInterceptorService, multi: true },
    { provide: MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ServiceViewComponent, DialogBausteinComponent],
})
export class AppModule { }

