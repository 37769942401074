<div class="stepper-container">
  <mat-progress-bar mode="indeterminate" style="height: 5px;" *ngIf="showProgress"></mat-progress-bar>
  <div *ngIf="!showProgress" style="height: 5px; align-self: center"></div>

  <div class="stepper" *ngIf="toDisplay()">

    <div class="stepper-buttons-container-left">
      <button mat-button class="button-prev" (click)="navigate_prev()" [disabled]="isPrevDisabled()" [attr.aria-label]="'Navigate previous'"  matTooltip="Zur vorherigen Seite wechseln" [matTooltipClass]="'stepper-tooltip'">
        <span><mat-icon svgIcon="arrow_prev"></mat-icon></span>
        <span>Zurück</span>
      </button>
    </div>

    <mat-tab-group class="tabs" [(selectedIndex)]="selectedIndex" (selectedIndexChange)="navigateTo($event)">
      <mat-tab *ngFor="let i of navItemOrder" [disabled]="isInactive(i)">
        <ng-template mat-tab-label *ngIf="i < (navItemOrder.length)">
          <div class="step" [ngClass]="getFontClass(i)">{{ getComponentTitle(i) }}</div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

    <div class="stepper-buttons-container-right">
      <button mat-button class="button-next" (click)="navigate_next()" [disabled]="isNextDisabled()" [attr.aria-label]="'Navigate next'"  matTooltip="Zur nächsten Seite wechseln" [matTooltipClass]="'stepper-tooltip'">
        <span>{{textButton}}</span>
        <span><mat-icon svgIcon="arrow_next"></mat-icon></span>
      </button>
    </div>

  </div>
</div>
