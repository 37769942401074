import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { ServicesViewComponent } from '../services-view/services-view.component';
import {Texts} from '../../../texts';

@Component({
  selector: 'app-select-service-providers',
  templateUrl: './select-service-providers.component.html',
  styleUrls: ['./select-service-providers.component.css']
})
export class SelectServiceProvidersComponent implements OnInit {
  @ViewChild('qcomp') qcomp: ServicesViewComponent;

  public readonly STORAGE_KEY = "SERVICE_PROVIDERS";
  public readonly QUESTION = "Welche Services werden durch Ihre eigene IT intern bereitgestellt und welche durch einen externen Dienstleister (Outsourcing)?";
  public readonly INSTRUCTION = Texts.SELECT_SERVICE + Texts.MORE_INFO;
  public readonly INFO = {
    title: 'Outsourcing',
    content:    `
    <p>Welche Services werden durch Ihre eigene IT intern bereitgestellt und welche durch einen externen Dienstleister (Outsourcing)? Sie k&ouml;nnen auch die &uuml;bergeordneten Services im unteren Bereich als &ldquo;Extern&rdquo; markieren, falls Sie diese outgesourct haben. Services, die Sie vorab als nicht vorhanden gekennzeichnet haben, sind hier ausgegraut.</p>
    <p>Klicken Sie auf die Services und w&auml;hlen Sie aus, ob diese in Ihrem Unternehmen intern betrieben werden oder durch einen externen Dienstleister. Falls Sie sich unsicher sind, k&ouml;nnen Sie &ldquo;wei&szlig; nicht&rdquo; ausw&auml;hlen. Beachten Sie jedoch, dass das Ergebnis der Plattform ungenauer werden kann, je &ouml;fter Sie &ldquo;wei&szlig; nicht&rdquo; ausw&auml;hlen.</p>
    `,
  }
  public readonly OPTIONS = {
    'isToggle': false,
    'array': [
      {'value' : '1', 'label': 'Wird intern bereitgestellt', 'style': 'empty' },
      {'value' : '2', 'label': 'Wird extern bereitgestellt', 'style': 'full' },
      {'value' : '0', 'label': 'weiß nicht', 'style': 'classic' },
      {'value' : '-1', 'label': 'Obligatorische Sicherheitsanforderungen', 'style': 'warning', 'explanation': 'Diese Maßnahme wird automatisch ausgewählt'}
    ],

  };
  public readonly DEFAULT_OPTION = 0;

  constructor(private app: AppService) { }

  ngOnInit(): void {
  }

  routeAnimationDone() {
  }

  getComponentName(): string {
    return "SelectServiceProvidersComponent";
  }
}
