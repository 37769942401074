import { NgModule } from '@angular/core';

import  {  DomSanitizer,  SafeResourceUrl  }  from  '@angular/platform-browser';
import  {  MaterialModule  }  from  '../material/material.module';
import  {  MatIconRegistry  }  from  '@angular/material/icon';

@NgModule({
  declarations: [],
  imports: [
    MaterialModule,
  ]
})
export class MaterialIconsModule {
  private  path:  string  =  '../../assets/icons';

  private icons: Map<string, string> = new Map([
    ['assessment', 'assessment-24px.svg'],
    ['menu', 'menu-24px.svg'],
    ['login', 'login_black_24dp.svg'],
    ['logout', 'logout_black_24dp.svg'],
    ['logged-in', 'person-24px.svg'],
    ['logged-out', 'person_outline-24px.svg'],
    ['visibility', 'visibility-24px.svg'],
    ['visibility_off', 'visibility_off-24px.svg'],
    ['prev', 'navigate_before-24px.svg'],
    ['next', 'navigate_next-24px.svg'],
    ['add_circle', 'add_circle_outline-24px.svg'],
    ['info', 'info.svg'],
    ['classic-circle', 'questionMark.svg'], // question mark instead of classic-circle -> this was the easy way
    ['baustein', 'baustein.svg'],
    ['half-circle','half-circle.svg'],
    ['empty-circle','empty-circle.svg'],
    ['full-circle','full-circle.svg'],
    ['warning-circle', 'warning_FILL0_wght400_GRAD0_opsz48.svg'],
    ['baustein-prio', 'baustein_prio.svg'],
    ['baustein-lego', 'baustein_lego.svg'],
    ['baustein-prio-only', 'baustein_prio_only.svg'],
    ['close', 'close_black_24dp.svg'],
    ['feedback', 'feedback_black_24dp.svg'],
    ['chevron_right', 'ic_chevron_right_24px.svg'],
    ['expand_more', 'ic_expand_more_24px.svg'],
    ['unfold-more', 'unfold_more.svg'],
    ['unfold-less', 'unfold_less.svg'],
    ['restart', 'restart.svg'],
    ['info', 'info_black_24dp.svg'],
    ['download', 'download.svg'],
    ['arrow_prev', 'arrow_prev.svg'],
    ['arrow_next', 'arrow_next.svg'],
    ['warning', 'warning_FILL0_wght400_GRAD0_opsz48.svg'],
    ['effort-high','Icons_GrundschutzPlus_Final_Aufwand_hoch.svg'],
    ['effort-medium','Icons_GrundschutzPlus_Final_Aufwand_mittel.svg'],
    ['effort-low','Icons_GrundschutzPlus_Final_Aufwand_niedrig.svg'],
    ['threat','warning_FILL0_wght400_GRAD0_opsz48.svg'],
    ['din','Icons_GrundschutzPlus_Final_DIN.svg'],
    ['comment-no','Icons_GrundschutzPlus_Final_keine_Kommentare.svg'],
    ['comment','Icons_GrundschutzPlus_Final_Kommentare.svg'],
    ['criticality-high','Icons_GrundschutzPlus_Final_Kritikalität_hoch.svg'],
    ['criticality-medium','Icons_GrundschutzPlus_Final_Kritikalität_mittel.svg'],
    ['criticality-low','Icons_GrundschutzPlus_Final_Kritikalität_niedrig.svg'],
    ['category','category_FILL0_wght400_GRAD0_opsz48.svg']
  ])

  constructor(
    private  domSanitizer:  DomSanitizer,
    public  matIconRegistry:  MatIconRegistry
  )
  {
    for(let [key, icon] of this.icons) {
      this.matIconRegistry.addSvgIcon(key, this.setPath(`${this.path}/${icon}`))
    }
  }
  private  setPath(url:  string):  SafeResourceUrl  {
    return  this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
