<div matTooltipPosition="right" [matTooltip]="description" [matTooltipClass]="'service'">
  <div *ngIf="!isoverarchingService" class="service-view" [matMenuTriggerFor]="menu" [class.disabled]="disabled">
    <div class="icon-container">
      <mat-icon *ngIf="!disabled" style="--icon-color: {{color}}" svgIcon="{{style}}-circle"></mat-icon>
      <mat-icon *ngIf="disabled" style="--icon-color: transparent" svgIcon="{{style}}-circle"></mat-icon>
    </div>
    <div class="text-container">{{nameShort}}</div>
  </div>

  <div *ngIf="isoverarchingService && overarchingSelectable" class="service-view" [matMenuTriggerFor]="menu" [class.disabled]="disabled">
    <div class="icon-container">
      <mat-icon *ngIf="!disabled" style="--icon-color: {{color}}" svgIcon="{{style}}-circle"></mat-icon>
      <mat-icon *ngIf="disabled" style="--icon-color: transparent" svgIcon="{{style}}-circle"></mat-icon>
    </div>
    <div class="text-container">{{nameShort}}</div>
  </div>

  <div *ngIf="isoverarchingService && !overarchingSelectable" class="overarching-service-view" [class.disabled]="disabled">
    <div class="icon-container">
      <mat-icon *ngIf="!disabled" svgIcon="warning"></mat-icon>
    </div>
    <div class="text-container">{{nameShort}}</div>
  </div>

</div>

<!-- Nenu -->
<mat-menu #menu="matMenu">
  <ng-container *ngFor="let option of options.array; index as i;">
    <ng-container *ngIf="key == 'SERVICES_CRITICALITY' || (key != 'SERVICES_CRITICALITY'  && i !== options.array.length - 1)">
      <button [matTooltip]="option.explanation" matTooltipPosition="right" [matTooltipClass]="'service-menu'" mat-menu-item (click)="onOptionSelected(i)">
        <mat-icon style="--icon-color: {{color}}" svgIcon="{{option.style}}-circle"></mat-icon>
        <span>{{option.label}}</span>
    </button>
    <mat-divider *ngIf="(key == 'SERVICES_CRITICALITY' && i !== options.array.length - 1) || (key != 'SERVICES_CRITICALITY' && i !== options.array.length - 2)" style="margin: 0 7px;"></mat-divider>
    </ng-container>

  </ng-container>
</mat-menu>
