import { Component, Inject, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { BausteinDto } from 'build/openapi';
import { BausteinService } from 'src/app/services/baustein.service';


export interface DialogData {
  bausteinId: number;
}

@Component({
  selector: 'app-dialog-baustein',
  templateUrl: './dialog-baustein.component.html',
  styleUrls: ['./dialog-baustein.component.css']
})
export class DialogBausteinComponent implements OnInit {
  baustein: BausteinDto;
  priority = 0;
  priorityString: string;


  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private dialog: MatDialog, private bausteinService: BausteinService, private elementRef:ElementRef) {
    this.baustein = bausteinService.bausteine.get(data.bausteinId);
    this.priorityString = this.generatePriorityString();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit () {
    if(this.elementRef.nativeElement.querySelector('a')){
      var tmp = this.baustein.requirement;
      tmp = tmp.split("'")[1];
      this.elementRef.nativeElement.querySelector('a').addEventListener('click', this.onClickRef.bind(this, tmp));
    }
  }

  private generatePriorityString(): string {
    if(this.priority <= 0) {
      return "";
    } else if(this.priority <= 1) {
      return "hoch";
    } else if(this.priority <= 2) {
      return "mittel";
    } else {
      return "niedrig";
    }
  }

  onClickRef(key: string) {
    event.preventDefault();
    this.dialog.open(DialogBausteinComponent, {
      data: {
        bausteinId: this.bausteinService.getIdByKey(key),
      }
    });
  }
}
