export const bausteinClasses = new Map(
    [
        [
            "ISMS",
            {
                'title': 'Sicherheitsmanagement',
                'colorPri': '#ff0000',
                'colorSec': 'ff1111',
            },
        ],
        [
            "ORP",
            {
                'title': 'Organisation und Personal',
                'colorPri': '#33cc33',
                'colorSec': '#44cc44',
            },
        ],
        [
            "CON",
            {
                'title': 'Konzeption und Vorgehensweise',
                'colorPri': '#1e2eff',
                'colorSec': '#1e2eff',
            },
        ],
        [
            "OPS",
            {
                'title': 'Betrieb',
                'colorPri': '#ff9933',
                'colorSec': '#ff9933',
            },
        ],
        [
            "DER",
            {
                'title': 'Detektion und Reaktion',
                'colorPri': '#030089',
                'colorSec': '#030089',
            },
        ],
        [
            "APP",
            {
                'title': 'Anwendungen',
                'colorPri': '#696969',
                'colorSec': '#696969',
            },
        ],
        [
            "SYS",
            {
                'title': 'IT-Systeme',
                'colorPri': '#006600',
                'colorSec': '#006600',
            },
        ],
        [
            "IND",
            {
                'title': 'Industrielle IT',
                'colorPri': '#800000',
                'colorSec': '#800000',
            },
        ],
        [
            "NET",
            {
                'title': 'Netze und Kommunikation',
                'colorPri': '#a39b00',
                'colorSec': '#a39b00',
            },
        ],
        [
            "INF",
            {
                'title': 'Infrastruktur',
                'colorPri': '#6300a6',
                'colorSec': '#6300a6',
            },
        ],
        [
            "FR",
            {
                'title': 'Foundational Requirements',
                'colorPri': '#004509',
                'colorSec': '#004509',
            },
        ],
    ]
);
