<div class="container">
    <div style="display: flex; justify-content: center">
        <div class="question">
            Willkommen zum Grundschutz<sup><small>PLUS</small></sup> Aktivator
        </div>
    </div>
    <div class="content">
        <div style="width: 75%; text-align: justify;">
            <p><strong>Was ist der Grundschutz<sup>PLUS</sup> Aktivator?</strong></p>
            <p>Der Grundschutz<sup>PLUS</sup> Aktivator ist eine interaktive Plattform mit der Sie ein auf Ihr Unternehmen zugeschnittenes Informationssicherheits-Konzept entwickeln und umsetzen k&ouml;nnen. Hierzu stellen wir Ihnen auf den folgenden Seiten einige Fragen zu Ihrem Unternehmen. Darauf basierend erstellen wir einen auf Ihr Unternehmen zugeschnittenen Katalog aus Informationssicherheitsanforderungen, Ma&szlig;nahmen und Umsetzungshilfen, der Ihnen bei der Erh&ouml;hung Ihres Informationssicherheitsniveaus helfen wird. Das daraus resultierende Informationssicherheitskonzept tr&auml;gt dazu bei, Ihr Unternehmen vor Cyberangriffen zu sch&uuml;tzen und Datendiebstahl sowie Ausf&auml;lle zu verhindern. Weitere Informationen zum Management von Informationssicherheit erhalten Sie beim <a class="external-link" href="https://www.bsi.bund.de/DE/Themen/Unternehmen-und-Organisationen/Standards-und-Zertifizierung/IT-Grundschutz/BSI-Standards/BSI-Standard-200-1-Managementsysteme-fuer-Informationssicherheit/bsi-standard-200-1-managementsysteme-fuer-informationssicherheit_node.html" rel="nofollow" target="_blank">Bundesamt f&uuml;r Sicherheit in der Informationstechnik (BSI)</a>.</p>
            <p><strong>Die Plattform</strong></p>
            <p>Vieles befindet sich zurzeit noch in Entwicklung, daher sind einige Features noch nicht vollst&auml;ndig implementiert. Jedoch bietet Ihnen die aktuelle Version bereits die M&ouml;glichkeit, die in Ihrem Unternehmen vorkommenden Services bzw. Dienste auszuw&auml;hlen. Daraus werden die f&uuml;r Ihr Unternehmen relevanten IT-Grundschutz-Bausteine und Handlungsempfehlungen ermittelt. Die Checklisten k&ouml;nnen nach Stichw&ouml;rtern durchsucht, gefiltert und gruppiert werden. Wenn Sie m&ouml;chten, k&ouml;nnen Sie die Checkliste als PDF-Datei exportieren. Die inhaltliche Grundlage des Grundschutz<sup>PLUS</sup> Aktivators liefert haupts&auml;chlich der <a class="external-link" href="https://www.bsi.bund.de/DE/Themen/Unternehmen-und-Organisationen/Standards-und-Zertifizierung/IT-Grundschutz/it-grundschutz_node.html" rel="nofollow" target="_blank">BSI IT-Grundschutz</a>, das <a class="external-link" href="https://www.bsi.bund.de/SharedDocs/Downloads/DE/BSI/ICS/ICS-Security_kompendium_pdf.html" rel="nofollow" target="_blank">BSI ICS-Kompendium</a> und die Normenreihe IEC 62443.</p> Rechts oben im Menü finden Sie ein Glossar, das viele wichtige Begriffe rund um das Thema Informationssicherheit erklärt.
            <p>Sie k&ouml;nnen einen Account f&uuml;r die Plattform erstellen, um Ihren Fortschritt ger&auml;te&uuml;bergreifend zu speichern. Den vollen Funktionsumfang der Plattform können Sie nur mit Account nutzen. Ohne Account wird Ihr Fortschritt nur im Cache des Browsers gespeichert.</p>
            <p>Alles, was Sie zur Nutzung der Plattform wissen m&uuml;ssen, wird Ihnen auf den einzelnen Unterseiten erkl&auml;rt. Auf vielen Seiten k&ouml;nnen Sie mit einem Klick auf das Fragezeichen zus&auml;tzliche Informationen anzeigen. Der Zeitaufwand betr&auml;gt ca. 30 Minuten. Sie k&ouml;nnen die Plattform beliebig oft nutzen. Die letzte Unterseite liefert konkrete Informationen zur Umsetzung f&uuml;r Ihr Unternehmen.</p>
            <p><strong>Geplante Entwicklungen</strong></p>
            <p>Weitere Informationen und Umsetzungshilfen werden nach und nach hinzugef&uuml;gt und aktualisiert. Das Design und die Benutzerfreundlichkeit der Plattform wird fortlaufend verbessert. Weitere Informationen zu Neuerungen oder den n&auml;chsten Veranstaltungen erhalten Sie bequem &uuml;ber unsere Webseite&nbsp;<a class="external-link" href="https://security4kmu.de/" rel="nofollow" style="text-align: justify;" target="_blank">Security4KMU</a> oder unseren&nbsp;<a class="external-link" href="https://security4kmu.de/newsletter-anmeldung" rel="nofollow" target="_blank">Newsletter</a>.</p>
            <p><strong>Ihr Feedback ist uns wichtig</strong></p>
            <p>Deshalb k&ouml;nnen Sie uns Anmerkungen und Fragen jederzeit &uuml;ber den gelben Feedback Button unten rechts auf jeder Seite zusenden. Wir freuen uns sowohl auf Ihr inhaltliches Feedback als auch auf Ihre Anmerkungen und Verbesserungsvorschl&auml;ge zur Benutzeroberfl&auml;che der Seite.</p>
            <p>Das Mittelstand-Digital Netzwerk bietet mit den Mittelstand-Digital Zentren, der Initiative IT-Sicherheit in der Wirtschaft und Digital Jetzt umfassende Unterstützung bei der Digitalisierung. Kleine und mittlere Unternehmen profitieren von konkreten Praxisbeispielen und passgenauen, anbieterneutralen Angeboten zur Qualifikation und IT-Sicherheit. Das Bundesministerium für Wirtschaft und Klimaschutz ermöglicht die kostenfreie Nutzung und stellt finanzielle Zuschüsse bereit. Weitere Informationen finden Sie unter <a class="external-link" href="www.it-sicherheit-in-der-wirtschaft.de" rel="nofollow" style="text-align: justify;" target="_blank">it-sicherheit-in-der-wirtschaft</a>.</p>
            <p>Ihre eingegebenen Informationen werden anonym zusammen mit einer zufällig generierten UUID an unseren Server übertragen und werden uns sehr bei der weiteren Entwicklung des GrundschutzPLUS Aktivators unterstützen.</p>
            <p>Herzlichen Dank für Ihre Mitarbeit.</p>
          </div>
    </div>
    <div style="align-self: center; margin: 50px">
        <button mat-button class="button-start" (click)="onStartClicked()" [disabled]="isNextDisabled()" [attr.aria-label]="'Start'" style="float:center">Start</button>
    </div>
    <swal #loginAlert *ngIf="!isLoggedIn()"
        text="Sie haben bereits einen Account? Melden Sie sich an, um mit der Bearbeitung Ihres Profils fortzufahren."
        showDenyButton="true">
        <ng-container *swalPortal="swalTargets.confirmButton">
            Anmelden
        </ng-container>
        <ng-container *swalPortal="swalTargets.denyButton">
            Ohne Anmeldung fortsetzen
        </ng-container>
    </swal>
    <app-footer></app-footer>
</div>
