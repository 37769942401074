<h2>Feedbackbox</h2>
<table mat-table [dataSource]="dataSource">

  <!-- Number column -->
  <ng-container matColumnDef="number">
    <th mat-header-cell *matHeaderCellDef>#</th>
    <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
  </ng-container>

  <!-- Feedback column -->
  <ng-container matColumnDef="feedback">
    <th mat-header-cell *matHeaderCellDef>Feedback</th>
    <td mat-cell *matCellDef="let element">{{ element.feedback }}</td>
  </ng-container>

  <ng-container matColumnDef="key">
    <th mat-header-cell *matHeaderCellDef>Key</th>
    <td mat-cell *matCellDef="let element">{{ element.key }}</td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">{{ element.date }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
