import { Injectable } from '@angular/core';
import { BausteinDto } from 'build/openapi';
import { BausteinControllerService } from 'build/openapi/api/bausteinController.service';
import { Observable, Subject } from 'rxjs';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class BausteinService {
  public static SHOW_BAUSTEIN_KEY: string | undefined;

  public static readonly ALL_BAUSTEINE_CHECKED = "ALL_BAUSTEINE_CHECKED";

  bausteineAll: Map<string, Array<BausteinDto>> = new Map();
  bausteineGSPlus: Set<number>;
  bausteineUser: Set<number> = new Set();
  bausteine: Map<number, BausteinDto> = new Map();

  private bausteineAllGeladen = new Subject<boolean>();
  private bausteineGSPlusGeladen = new Subject<boolean>();
  private bausteineUserGeladen = new Subject<boolean>();

  constructor(
    private appService: AppService,
    private bausteinControllerService: BausteinControllerService) {
  }

  public ladeBausteineAll() : Observable<boolean> {
    this.bausteineAll.clear();
    this.bausteinControllerService.getBausteineAll().subscribe((data: Array<BausteinDto>) => {
      data = data.sort((a, b) => {
        let a_s = a.key.split(".")
        let b_s = b.key.split(".")
        for(let i = 0; i < Math.min(a_s.length, b_s.length); ++i) {
          let l = a_s[i];
          let r = b_s[i];
          let nl: number = +l;
          let nr: number = +r;
          let res: number = 0;
          if(!isNaN(nl) && !isNaN(nr)) {
            res = nl - nr;
          } else {
            res = l.localeCompare(r);
          }
          if(res != 0) {
            return res;
          }
        }
        return a_s.length - b_s.length;
      })
      data.forEach(x => {
        this.bausteine.set(x.id, x);
        let key = this.getBaseKey(x.key);
        if(!this.bausteineAll.has(key)) {
          this.bausteineAll.set(key, Array());
        }
        this.bausteineAll.get(key).push(x);
      });
      this.bausteineAllGeladen.next(true);
    });
    return this.bausteineAllGeladen.asObservable();
  }

  public get allBausteine() : boolean {
    const userData = this.appService.getUserData(BausteinService.ALL_BAUSTEINE_CHECKED);
    return userData ? userData.value === 'true' : false;
  }

  public getBausteine(baseKey: string): Array<BausteinDto> {
    const array = this.bausteineAll.get(baseKey);
    if(array && !this.allBausteine) {
      return array.filter(baustein => this.bausteineGSPlus.has(baustein.id));
    }
    return array;
  }

  public setAllBausteine(value: boolean) {
    if(this.allBausteine != value) {
      this.appService.sendUserData(BausteinService.ALL_BAUSTEINE_CHECKED, new Boolean(value).toString());
    }
  }

  public ladeBausteineGsplus() : Observable<boolean> {
    this.bausteinControllerService.getBausteineGSPlus().subscribe((data: Array<BausteinDto>) => {
      this.bausteineGSPlus = new Set(data.map(x => x.id));
      this.bausteineGSPlusGeladen.next(true);
    });
    return this.bausteineGSPlusGeladen.asObservable();
  }

  public ladeBausteineUser():  Observable<boolean> {
    this.bausteineUser.clear();
    if (this.appService.isLoggedIn()) {
      this.bausteinControllerService.getBausteineUser().subscribe((data: Array<BausteinDto>) => {
        this.handleGeladeneUserBausteine(data);
      });
    }
    else {
      this.bausteinControllerService.getBausteineUserAnonymous(this.appService.getUUID()).subscribe((data: Array<BausteinDto>) => {
        this.handleGeladeneUserBausteine(data);
      });
    }
    return this.bausteineUserGeladen.asObservable();
  }

  private handleGeladeneUserBausteine(data: BausteinDto[]) {
    data.forEach(x => {
      this.bausteineUser.add(x.id);
    });
    this.bausteineUserGeladen.next(true);
  }

  isUserBaustein(baustein: BausteinDto): boolean {
    return this.bausteineUser.has(baustein.id);
  }

  isGSPlusBaustein(baustein: BausteinDto): boolean {
    return this.bausteineGSPlus.has(baustein.id);
  }

  getBaseKey(fullKey: string): string {
    if(fullKey.startsWith('FR ')) {
      return fullKey.substr(0, fullKey.indexOf(' '));
    }
    return fullKey.substr(0, fullKey.indexOf('.'));
  }

  getIdByKey(key: string) {
    for (let [id, value] of this.bausteine) {
      if (value.key == key) {
         return id;
      }
    }
  }
}
