import { ChangeDetectorRef, ComponentFactoryResolver, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Service } from 'build/openapi/model/models';
import { Subscription } from 'rxjs';
import { Options } from '../../../model/options';
import { ServiceViewComponent } from '../service-view/service-view.component';
import { AppService } from '../../../services/app.service';
import { ServiceService } from '../../../services/service.service';

@Component({
  selector: 'app-service-class-view',
  templateUrl: './service-class-view.component.html',
  styleUrls: ['./service-class-view.component.css']
})
export class ServiceClassViewComponent implements OnInit, AfterViewInit {
  @ViewChild('servicesContainer', { read: ViewContainerRef }) servicesContainer: ViewContainerRef;

  @Input() options: Options;
  @Input() defaultOption: number;
  @Input() name: string;
  @Input() key: string;
  @Input() color: string;
  @Input() size: string;

  public label: string;

  public overarching: boolean = false;

  private services: Map<string, Service>;
  private subscription: Subscription;

  constructor(private serviceService: ServiceService, private resolver: ComponentFactoryResolver, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.initServices();
    if (this.name == 'it_overarching' || this.name == 'ot_overarching') {
      this.overarching = (this.key =='SERVICES_CRITICALITY' && this.serviceService.ifItSelected()) ? true: false;
    }
  }

  private initServices() {
    if(!this.services) {
      this.services = this.serviceService.services.get(this.name);
      if(this.services) {
        this.label = this.serviceService.serviceGroups.get(this.name).name;
        this.services.forEach(service => {
          const factory = this.resolver.resolveComponentFactory(ServiceViewComponent);
          var component = this.servicesContainer.createComponent(factory)
          component.instance.key = this.key;
          component.instance.options = this.options;
          component.instance.defaultOption = this.defaultOption;
          component.instance.nameShort = service.nameShort;
          component.instance.color = this.color;
          component.instance.description = service.description;
          component.instance.serviceKey = this.name + ':' + service.key;
          this.cdr.detectChanges(); // Necessary in order to avoid the ExpressionChangedAfterItHasBeenCheckedError
          if(this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = undefined;
          }
        })
      }
    }
  }

  onAllOptionSelected(index: number) {
    if (this.name == 'it_overarching') {this.serviceService.onAllOverarchingItOptionSelected(this.key, index);}
    else {this.serviceService.onAllOverarchingOtOptionSelected(this.key, index);}
  }
}
