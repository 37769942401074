import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BausteinDto } from 'build/openapi';
import { Subscription } from 'rxjs';
import { DialogBausteinComponent } from '../dialog-baustein/dialog-baustein.component';
import { BausteinService } from '../../../services/baustein.service';

@Component({
  selector: 'app-baustein',
  templateUrl: './baustein.component.html',
  styleUrls: ['./baustein.component.scss']
})
export class BausteinComponent implements OnInit, OnDestroy {
  private SUBSCRIPTION_CHANGE_GLOBAL: Subscription;

  public label: string = "TEST";
  @Input() primaryColor: string = "#000";
  @Input() secondaryColor: string = "#fff";
  @Input() disabled: boolean = false;
  @Input() baustein: BausteinDto;

  constructor(
    private dialog: MatDialog,
    private elRef: ElementRef,
    private bausteinService: BausteinService,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.label = this.baustein.key;
    this.setCssVariables();
    this.cdr.detectChanges();
    this.setCssVariables();
  }

  ngAfterViewInit(): void {
    if (BausteinService.SHOW_BAUSTEIN_KEY === this.baustein.key) {
      BausteinService.SHOW_BAUSTEIN_KEY = undefined;
      this.onClick();
    }
  }

  ngOnDestroy(): void {
    if (this.SUBSCRIPTION_CHANGE_GLOBAL) {
      this.SUBSCRIPTION_CHANGE_GLOBAL.unsubscribe();
    }
  }

  setCssVariables(): void {
    this.elRef.nativeElement.style.setProperty('--primary-color', this.primaryColor);
    this.elRef.nativeElement.style.setProperty('--secondary-color', this.secondaryColor);
    this.elRef.nativeElement.style.setProperty('--opacity', this.disabled ? '0.2' : '1.0');
  }

  isDisabled(baustein: BausteinDto) {
    return !this.bausteinService.isUserBaustein(baustein);
  }

  isDisabledStrong(baustein: BausteinDto) {
    return !this.bausteinService.isGSPlusBaustein(baustein);
  }

  onClick() {
    this.dialog.open(DialogBausteinComponent, {
      data: {
        bausteinId: this.baustein.id,
      }
    });
  }
}
