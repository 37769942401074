import { Injectable } from '@angular/core';
import { GlossaryControllerService, GlossaryDto } from 'build/openapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GlossaryService {

  constructor(private glossaryControllerService: GlossaryControllerService) { }

  public loadGlossary(): Observable<GlossaryDto[]> {
    return this.glossaryControllerService.getAllGlossary().pipe(
      map((data: any[]) => {
        return data.map(item => {
          const glossaryDto: GlossaryDto = {
            term: item.term,
            description: item.description,
          };
          return glossaryDto;
        });
      })
    );
  }
}
