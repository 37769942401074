<div  [formGroup]="filterForm">
  <mat-card *ngIf="isLoggedIn()" appearance="outlined" class="checklist-filter-card">
    <div >
      <canvas baseChart width="270px" height="270px"
        [type]="'pie'"
        [datasets]="pieChartDatasets"
        [labels]="pieChartLabels"
        [options]="pieChartOptions"
        [plugins]="pieChartPlugins"
        [legend]="pieChartLegend">
      </canvas>
    </div>
  </mat-card>
  <mat-card appearance="outlined" class="checklist-filter-card">
    <div class="checklist-filter-label-1">Volltextsuche</div>
    <mat-form-field appearance="outline">
      <input matInput class="filter-input" (keyup)="applyFilter()" formControlName="searchTerm" autocomplete="off" />
      <button *ngIf="filterForm.value.searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="resetTextFilter()">
        <mat-icon matTooltip="Filter zurücksetzen" svgIcon="close"></mat-icon>
      </button>
    </mat-form-field>
    <div class="checklist-filter-label-1">Gruppierung</div>
    <mat-form-field appearance="outline">
      <mat-select [(value)]="selectedGruppierung" (selectionChange)="gruppierungSelected($event.value)">
        <mat-option *ngFor="let option of gruppierungOptionen() | keyvalue" [value]="option.value">
          {{ option.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="export-box" (click)="exportAsPdf()">
      <span class="navigate-to-rmi-label mat-step-label-selected">Export als PDF</span>
      <span mat-button style="margin-top: 5px;">
        <img src="assets/icons/download.svg" alt="navigate" height="22px" />
      </span>
    </div>
  </mat-card>
  <mat-card appearance="outlined" class="checklist-filter-card">
    <div>
      <div class="checklist-filter-label-1">Rollen</div>
      <ul class="list-selected" *ngIf="filterForm.value.rollen && filterForm.value.rollen.length > 0">
        <li *ngFor="let rolle of filterForm.value.rollen">
          <div class="list-selected-value-container">
            <button mat-icon-button (click)="entferneRolle(rolle)">
              <mat-icon matTooltip="Rolle entfernen" svgIcon="close"></mat-icon>
            </button>
            {{ rolle }}
          </div>
        </li>
      </ul>
      <mat-form-field appearance="outline">
        <mat-select [formControl]="rollen" multiple (selectionChange)="applyFilter()">
          <mat-option *ngFor="let rolle of rollenList" [value]="rolle">{{rolle}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <div class="checklist-filter-label-2">Bedrohungen</div>
        <ul *ngIf="filterForm.value.bedrohungen && filterForm.value.bedrohungen.length > 0" class="list-selected">
          <li *ngFor="let bedrohung of filterForm.value.bedrohungen">
            <div class="list-selected-value-container">
              <button mat-icon-button (click)="entferneBedrohung(bedrohung)">
                <mat-icon matTooltip="Bedrohung entfernen" svgIcon="close"></mat-icon>
              </button>
              {{ bedrohung }}
            </div>
          </li>
        </ul>
      <mat-form-field appearance="outline">
        <mat-select [formControl]="bedrohungen" multiple (selectionChange)="applyFilter()">
          <mat-option *ngFor="let bedrohung of bedrohungenList" [value]="bedrohung">{{bedrohung}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div formArrayName="kritikalitaeten">
      <div class="checklist-filter-label-2">Kritikalität</div>
      <ul class="list-options">
        <li *ngFor="let kritikalitaet of kritikalitaeten.controls; let i = index">
          <mat-checkbox [formControlName]="i" id="kritikalitaet-{{ i }}" (change)="applyFilter()">{{
            massnahmeService.kritikalitaeten[i] }}</mat-checkbox>
        </li>
      </ul>
    </div>
    <div formArrayName="anwendungsbereiche">
      <div class="checklist-filter-label-2">Anwendungsbereich</div>
      <ul class="list-options">
        <li *ngFor="
            let anwendungsbereich of anwendungsbereiche.controls;
            let i = index
          ">
          <mat-checkbox [formControlName]="i" id="anwendungsbereich-{{ i }}" (change)="applyFilter()">{{
            massnahmeService.anwendungsbereiche[i] }}</mat-checkbox>
          </li>
        </ul>
      </div>
      <div formArrayName="kategorien">
        <div class="checklist-filter-label-2">Kategorie</div>
        <ul class="list-options">
          <li *ngFor="let kategorie of kategorien.controls; let i = index">
            <mat-checkbox [formControlName]="i" id="kategorie-{{ i }}" (change)="applyFilter()">{{
              massnahmeService.kategorien[i] }}</mat-checkbox>
          </li>
        </ul>
      </div>
      <div formArrayName="aufwaende">
        <div class="checklist-filter-label-2">Aufwand</div>
        <ul class="list-options">
          <li *ngFor="let aufwand of aufwaende.controls; let i = index">
            <mat-checkbox [formControlName]="i" id="aufwand-{{ i }}" (change)="applyFilter()">{{
              massnahmeService.aufwaende[i] }}</mat-checkbox>
          </li>
        </ul>
      </div>

      <div formArrayName="din">
        <div class="checklist-filter-label-2">DIN SPEC 27076</div>
        <ul class="list-options">
          <li>
            <mat-checkbox [formControlName]="0" id="din-0" (change)="applyFilter()">Teil der Norm</mat-checkbox>
          </li>
          <li>
            <mat-checkbox [formControlName]="1" id="din-1" (change)="applyFilter()">nicht Teil der Norm</mat-checkbox>
          </li>
        </ul>
      </div>

      <div *ngIf="isLoggedIn()" formArrayName="activeCheckboxString">
        <div class="checklist-filter-label-2">Bearbeitet</div>
        <ul class="list-options">
          <li>
            <mat-checkbox [formControlName]="0" id="activeCheckboxString-0" (change)="applyFilter()">bearbeitet</mat-checkbox>
          </li>
          <li>
            <mat-checkbox [formControlName]="1" id="activeCheckboxString-1" (change)="applyFilter()">nicht bearbeitet</mat-checkbox>
          </li>
        </ul>
      </div>

      <div *ngIf="isLoggedIn()" formArrayName="relevanz">
        <div class="checklist-filter-label-2">Relevanz</div>
        <ul class="list-options">
          <li>
            <mat-checkbox [formControlName]="0" id="relevanz-0" (change)="applyFilter()">relevant</mat-checkbox>
          </li>
          <li>
            <mat-checkbox [formControlName]="1" id="relevanz-1" (change)="applyFilter()">nicht relevant</mat-checkbox>
          </li>
        </ul>
      </div>

      <div *ngIf="isLoggedIn()" formArrayName="comment">
        <div class="checklist-filter-label-2">Kommentar</div>
        <ul class="list-options">
          <li>
            <mat-checkbox [formControlName]="0" id="comment-0" (change)="applyFilter()">mit Kommentar</mat-checkbox>
          </li>
          <li>
            <mat-checkbox [formControlName]="1" id="comment-1" (change)="applyFilter()">ohne Kommentar</mat-checkbox>
          </li>
        </ul>
      </div>



  </mat-card>
</div>
