import { KeycloakEvent, KeycloakEventType, KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

export function initializer(keycloak: KeycloakService, authService: AuthService): () => Promise<boolean> {

  const options: KeycloakOptions = {
    config: environment.keycloak,
    loadUserProfileAtStartUp: true,
    initOptions: {
      onLoad: 'check-sso',
      checkLoginIframe: false,
    },
    enableBearerInterceptor: true,
    bearerPrefix: 'Bearer',

  };

  keycloak.keycloakEvents$.subscribe((event: KeycloakEvent) => {
    if (event.type === KeycloakEventType.OnAuthSuccess) {
      authService.handleAuthSuccess();
    }
    else if (event.type === KeycloakEventType.OnAuthLogout) {
      authService.handleLogout();
    }
  });

  return () => keycloak.init(options);
}