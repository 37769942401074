import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BausteinService } from '../services/baustein.service';

@Injectable()
export class BausteineGuard implements CanActivate {
  constructor(
    private readonly bausteinService: BausteinService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> {
    return combineLatest([
      this.bausteinService.ladeBausteineAll(),
      this.bausteinService.ladeBausteineGsplus(),
      this.bausteinService.ladeBausteineUser(),
    ]).pipe(map(([a, b]) => a && b));
  }
}
