import { Component, OnInit } from '@angular/core';
import { GlossaryDto } from 'build/openapi';
import { GlossaryService } from 'src/app/services/glossary.service';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.css']
})
export class GlossaryComponent implements OnInit {
  private glossaryData: GlossaryDto[];
  public dataTable;
  public displayedColumns;

  constructor(private glossaryService: GlossaryService) {this.loadGlossary();}

  ngOnInit(): void {

  }

  private loadGlossary(): void {
    this.glossaryService.loadGlossary().subscribe(
      (data: GlossaryDto[]) => {
        this.glossaryData = data;
        this.displayedColumns = ['term', 'description'];
        this.dataTable = new MatTableDataSource(this.glossaryData);
      },
      (error: any) => {
      }
    );
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataTable.filter = filterValue.trim().toLowerCase();

    if (this.dataTable.paginator) {
      this.dataTable.paginator.firstPage();
    }
  }

}
