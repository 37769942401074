import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { AuthService } from 'src/app/auth/auth.service';
import { AppService } from 'src/app/services/app.service';
import { Texts } from 'src/app/texts';
import { SweetAlertOptions } from 'sweetalert2';
import { RouteService } from '../../services/route.service';
@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.css']
})
export class ChecklistComponent implements OnInit {
  readonly QUESTION = "Checkliste zur Umsetzung der wichtigsten IT-Sicherheitsmaßnahmen";
  readonly INSTRUCTION:string = "Die Checklist enthält die wichtigsten, auf Ihr Unternehmen zugeschnittenen IT-Sicherheitsmaßnahmen. "+ Texts.MORE_INFO;
  readonly INFO = {
    title: "Checkliste",
    content:
    `
    <p><b>Der volle Funktionsumfang dieser Seite steht Ihnen nur mit Benutzerkonto zur Verfügung!</b></p>
    <p>Die Checklist enthält die wichtigsten, auf Ihr Unternehmen zugeschnittenen IT-Sicherheitsmaßnahmen. Die Ma&szlig;nahmen sind standardm&auml;&szlig;ig entsprechend ihrer Bausteine gruppiert und k&ouml;nnen abgehakt werden. Dabei werden Sie stets aufgefordert, Ihre Umsetzung zu kommentieren. Dies sollten Sie tun, um im Nachhinein besser nachvollziehen zu können, was Sie genau umgesetzt haben.</p>
<p>Auf der linken Seite finden Sie verschiedene Sortierungs- bzw. Filterfunktionen:</p>
<ul>
    <li>Volltextsuche innerhalb der Ma&szlig;nahmen</li>
    <li>Gruppierung der Ma&szlig;nahmen nach Baustein, Kritikalit&auml;t, Anwendungsbereich, Kategorie oder Aufwand</li>
    <li>Filterung nach<ul>
            <li>Verantwortlichen Rollen</li>
            <li>Relevanten Bedrohungen</li>
            <li>Kritikalit&auml;t, Anwendungsbereich, Kategorie oder Aufwand</li>
            <li>Bearbeitete Maßnahmen</li>
            <li>Zugehörigkeit zur DIN SPEC 27076</li>
            <li>Kommentierten Maßnahmen</li>
            <li>Als nicht relevant markierten Maßnahmen</li>
        </ul>
    </li>
</ul>
<p>Oben links können Sie in einem Kuchendiagramm sehen, wie viele der Maßnahmen Sie in den einzelnen Bereichen bereits abgehakt haben. Zusätzlich können Sie den aktuellen Status der Checkliste (inkl. angewandter Filter und Gruppierung) als PDF-Datei herunterladen.</p>
<p>Unter jeder Maßnahme befinden sich auf der linken Seite mehrere Icons, die Ihnen Informationen zu verantwortlichen Rollen, Bedrohungen, Kritikalität, Kategorie und Aufwand der Maßnahme liefern, wenn Sie die Maus darüber bewegen. Auch relevante DIN SPEC 27076 Anforderungen werden hier angezeigt, falls verfügbar. Auf der rechten Seite finden Sie Buttons, um einen Kommentar zu dieser Maßnahme zu schreiben (z.B. wie Sie die Maßnahme konkret in Ihrem Unternehmen umgesetzt haben) oder um die Maßnahme als nicht relevant zu markieren. Die Auswahl von Maßnahmen als nicht relevant sollte im darauffolgenden Textfeld immer begründet werden.</p>
<p>Falls es eine Vorlage oder ein Dokument zu einer Maßnahme gibt, finden Sie diese unter dem Punkt “Umsetzungsdokumente”. Zur eindeutigen Zuordnung ist jeder Maßnahme eine ID zugeordnet. Diese ID enthält den Baustein, aus dem die Maßnahme kommt (z.B. ORP.4), die ursprüngliche Maßnahme (z.B. M22) und die Nummer der Information innerhalb der ursprünglichen Maßnahme (z.B. C2). Der Begriff "ursprüngliche Maßnahme" bezeichnet hierbei den Wortlaut der Maßnahme nach BSI Umsetzungshinweisen. Darunter ist/sind die Quelle/n des Checks angegeben.</p>
`,
  }

  windowScrolled: boolean;

  @ViewChild('loginAlert')
  public readonly loginAlert!: SwalComponent;
  constructor(
    private appService: AppService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private route: RouteService,
    public swalTargets: SwalPortalTargets) {
    if (!this.appService.getUserData('CHECKLIST_VISITED')){
      this.appService.sendUserData('CHECKLIST_VISITED', 'true');
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.isLoggedIn()) {
        return;
      }
      this.loginAlert.update({
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        customClass: {
          confirmButton: 'button-bordered mat-button button-confirm',
          cancelButton: 'button-bordered mat-button button-cancel',
        }
      } as SweetAlertOptions);
      this.loginAlert.fire();
    }, 0);
    this.route.className = "ChecklistComponent";
    window.addEventListener('scroll', this.onScrollEvent.bind(this), true);
  }

  getComponentName(): string {
    return "ChecklistComponent";
  }

  navigate_prev(): void {
    this.route.routePrevName();
  }

  navigate_next(): void {
    this.route.routeNextName();
  }

  login() {
    this.authService.loginAfterRedirect('/enter');
  }

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  onScrollEvent(event: Event) {
    const scrollThreshold = 5;
    this.windowScrolled = (event.target as Element).scrollTop > scrollThreshold;
    this.cdr.detectChanges();
  }

  scrollToTop() {
    document.querySelector('#scroll').scrollIntoView({ behavior: 'smooth' });
  }
}

