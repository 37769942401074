<div class="checklist">

    <mat-expansion-panel
    *ngFor="let segment of segmenteList"
      [class]="segmentPanelOpened(accordion) ? 'checklist-item-parent-opened' : 'checklist-item-parent'"
      (closed)="closeSegmentPanel(accordion)"
      (opened)="openSegmentPanel(accordion)"
    >
      <mat-expansion-panel-header [ngClass]="segment.active ? 'mat-expansion-panel-header-active' : 'mat-expansion-panel-header-inactive'">

        <mat-panel-title>{{ getSegmentName(segment) }} ({{ segment.anzahlDerMassnahmen }}) </mat-panel-title>
        <mat-panel-description>
          <button mat-icon-button *ngIf="segmentPanelOpened(accordion)" [attr.aria-label]="'Alle aufklappen'" (click)="processMultiExpansion($event, accordion, segment.key)" style="margin-right: 17px">
            <mat-icon *ngIf="expanded(accordion)" svgIcon="unfold-less"></mat-icon>
            <mat-icon *ngIf="!expanded(accordion)" svgIcon="unfold-more"></mat-icon>
          </button>
        </mat-panel-description>

      </mat-expansion-panel-header>

      <mat-accordion multi #accordion="matAccordion">

        <ng-container *ngIf="segmentPanelOpened(accordion)">
          <mat-expansion-panel
            #mep="matExpansionPanel"
            *ngFor="let gruppe of getMassnahmenGruppenBySegment(segment.key)"
            [ngClass]="segmentPanelOpened(accordion) ? 'checklist-item-opened' : 'checklist-item'"
            (opened)="openMassnahmenGruppePanel(mep)"
            (closed)="closeMassnahmenGruppePanel(mep)"
            >
              <mat-expansion-panel-header [ngClass]="gruppe.active ? 'mat-expansion-panel-header-active' : 'mat-expansion-panel-header-inactive checklist-item-header'">
                <mat-panel-title>{{ gruppe.name }} ({{ gruppe.anzahlDerMassnahmen }}) </mat-panel-title>
              </mat-expansion-panel-header>

            <ng-container *ngIf="gruppePanelOpened(mep)">
              <div *ngFor="let massnahme of gruppe.massnahmen | keyvalue">
                <app-checklist-item *ngIf="massnahme.value.active" [massnahme]="massnahme.value"></app-checklist-item>
              </div>
            </ng-container>

          </mat-expansion-panel>
        </ng-container>

      </mat-accordion>

    </mat-expansion-panel>
</div>
