<h2 mat-dialog-title style="font-weight: bold;">Baustein {{baustein.key}} {{baustein.title}}</h2>
<div *ngIf="priorityString">
    <h3>Umsetzungspriorität: <b>{{priorityString}}</b></h3>
</div>
<mat-dialog-content>
    <div class="md-dialog-content">
        <div *ngIf="baustein.introduction">
            <h2>Einleitung</h2>
            <p [innerHTML]="baustein.introduction | formatString"></p>
        </div>
        <div *ngIf="baustein.goal">
            <h2>Zielsetzung</h2>
            <p [innerHTML]="baustein.goal | formatString"></p>
        </div>
        <div *ngIf="baustein.requirement">
            <h2>Anforderungen</h2>
            <p [innerHTML]="baustein.requirement | formatString"></p>
        </div>
        <div *ngIf="baustein.actionHint">
            <h2>Maßnahmen/Hinweise</h2>
            <p [innerHTML]="baustein.actionHint | formatString"></p>
        </div>
    </div>
</mat-dialog-content>
