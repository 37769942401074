import { Component, OnInit, ViewChild, } from '@angular/core';
import { ServiceViewComponent } from '../service-view/service-view.component';
import { AppService } from '../../../services/app.service';
import { ServicesViewComponent } from '../services-view/services-view.component';
import { Texts } from '../../../texts';

@Component({
  selector: 'app-select-services',
  templateUrl: './select-services.component.html',
  styleUrls: ['./select-services.component.css']
})
export class SelectServicesComponent implements OnInit {
  @ViewChild('qcomp') qcomp: ServicesViewComponent;

  public readonly STORAGE_KEY = ServiceViewComponent.SelectionKey;
  public readonly QUESTION = "Welche IT-Services nutzen bzw. betreiben Sie in Ihrem Unternehmen?";
  public readonly INSTRUCTION = Texts.SELECT_SERVICE + Texts.MORE_INFO;
  public readonly INFO = {
    title: 'Serviceauswahl',
    content:`
    <p>Welche IT-Services nutzen bzw. betreiben Sie in Ihrem Unternehmen? Klicken Sie auf die Services und wählen Sie aus, ob diese in Ihrem Unternehmen betrieben werden oder nicht. Falls Sie sich unsicher sind, können Sie “weiß nicht” auswählen. Beachten Sie jedoch, dass das Ergebnis der Plattform ungenauer werden kann, je öfter Sie “weiß nicht” auswählen. Wenn Sie die Maus über einen Service bewegen, wird ein kurzer Erklärungstext eingeblendet.</p>
    <p>Die Themengebiete im unteren rechten Bereich sind obligatorische Services, die immer betrachtet werden sollten, sobald Sie sich mit dem Thema Informationssicherheit auseinandersetzen. Aus diesem Grund werden diese Services automatisch mit ausgewählt, sobald Sie einen Service aus dem Bereich Office-IT bzw. Industrial-IT auswählen. Sie können diese Services nicht abwählen, da diese grundlegende Anforderungen an die Informationssicherheit stellen, die unabhängig von der Branche für jedes Unternehmen wichtig sind.</p>
    <p>Die Auswahl beeinflusst maßgeblich, welche Handlungsempfehlungen wir Ihnen liefern. Sollten Sie keinen Industrial-IT-Bereich in Ihrem Unternehmen haben, wählen Sie alle entsprechenden Services ab ("alle"-Button oben rechts). Im Folgenden wird dieser Bereich dann ausgeblendet. Wählen Sie auch Services aus, die Sie extern  bzw. outgesourct betreiben. Diese können Sie bei der weiteren Bearbeitung auf der Seite “Servicebetrieb” entsprechend als extern kennzeichnen.</p>
    `,
};
  public readonly OPTIONS = {
    'isToggle': false,
    'array': [
      {'value' : '2', 'label': 'Ausgewählt', 'style': 'full' },
      {'value' : '1', 'label': 'Nicht ausgewählt', 'style': 'empty' },
      {'value' : '0', 'label': 'Weiß nicht', 'style': 'classic' },
      {'value' : '-1', 'label': 'Obligatorische Sicherheitsanforderungen', 'style': 'warning', 'explanation': 'Diese Maßnahme wird automatisch ausgewählt'}
    ],
  };
  public readonly DEFAULT_OPTION = 1;

  constructor(private app: AppService) { }

  ngOnInit(): void {
  }

  routeAnimationDone() {
  }

  getComponentName(): string {
    return "SelectServicesComponent";
  }

  openDialog():void{
  }
}


