import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-base-question-buttons',
  templateUrl: './base-question-buttons.component.html',
  styleUrls: ['./base-question-buttons.component.css']
})
export class BaseQuestionButtonsComponent implements OnInit {
  @Input() key: string;
  @Input() question: string;
  @Input() options: string[];
  @Input() textField: number = -1;

  text: string;

  private static readonly STORAGE_KEY_PREFIX = "QUESTION_BUTTONS_";
  private static readonly STORAGE_KEY_PREFIX_QUESTION = "QUESTION_";
  private static readonly STORAGE_KEY_PREFIX_SELECTED_OPTION_INDEX = "OPTION_INDEX_";
  private static readonly STORAGE_KEY_PREFIX_SELECTED_OPTION_VALUE = "OPTION_VALUE_";

  selectedOptionIndex: number = -1;

  constructor(private appService: AppService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    let data = this.appService.getUserData(BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX + BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX_SELECTED_OPTION_INDEX + this.key);
    if (data) {
      var index = data.value;
      if (index) {
        this.selectedOptionIndex = Number(index);
        this.cdr.detectChanges();
      }
    }
    data = this.appService.getUserData(BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX + BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX_SELECTED_OPTION_VALUE + this.key);
    if (data) {
      if (this.options.indexOf(data.value) < 0) {
        this.text = data.value;
        this.cdr.detectChanges();
      }
    }
  }

  toggle_button() {
    this.appService.sendUserData(BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX + BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX_SELECTED_OPTION_INDEX + this.key, this.selectedOptionIndex.toString());
    this.appService.sendUserData(BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX + BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX_SELECTED_OPTION_VALUE + this.key, this.options[this.selectedOptionIndex]);
    this.appService.sendUserData(BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX + BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX_QUESTION + this.key, this.question);
  }

  isPrevDisabled(): boolean {
    return false;
  }

  isNextDisabled(): boolean {
    return this.selectedOptionIndex < 0;
  }

  onFocusIn() {
    this.appService.sendUserData(BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX + BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX_SELECTED_OPTION_VALUE + this.key, this.textField.toString());
    this.appService.sendUserData(BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX + BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX_QUESTION + this.key, this.question);
  }

  onFocusOut() {
    this.appService.sendUserData(BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX + BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX_SELECTED_OPTION_INDEX + this.key, this.selectedOptionIndex.toString());
    this.appService.sendUserData(BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX + BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX_SELECTED_OPTION_VALUE + this.key, this.text.toString());
    this.appService.sendUserData(BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX + BaseQuestionButtonsComponent.STORAGE_KEY_PREFIX_QUESTION + this.key, this.question);
  }

  routeAnimationDone() {
  }

  getSelectedTextField(): boolean {

    return this.options[this.selectedOptionIndex] == "Sonstiges" ? true : false;
  }
}
