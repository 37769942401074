import { Component, ViewChild } from '@angular/core';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import { AuthService } from '../../auth/auth.service';
import { RouteService } from '../../services/route.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent {
  key: string = 'LANDING_PAGE';

  @ViewChild('loginAlert')
  public readonly loginAlert!: SwalComponent;

  constructor(
    private route: RouteService,
    private authService: AuthService,
    public swalTargets: SwalPortalTargets) {
  }

  ngAfterViewInit(): void {
    if (this.loginAlert) {
      this.loginAlert.update({
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        customClass: {
          confirmButton: 'button-bordered mat-button button-confirm',
          denyButton: 'button-bordered mat-button button-cancel',
        },
        allowOutsideClick: false
      } as SweetAlertOptions);
    }
  }

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  isPrevDisabled(): boolean {
    return true;
  }

  isNextDisabled(): boolean {
    return false;
  }

  routeAnimationDone() {
    this.route.routeAnimationDone();
  }

  onStartClicked(): void {
    if (this.isLoggedIn()) {
      this.route.routeNext("LandingPageComponent");
    }
    else {
      this.loginAlert.fire().then((result) => {
        if (result.isConfirmed) {
          this.authService.loginAfterRedirect('/enter');
        }
        else if (result.isDenied) {
          this.route.routeNext("LandingPageComponent");
        }
      });
    }
  }
}
