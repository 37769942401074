<mat-divider></mat-divider>
<mat-toolbar class="logo-bar">
    <div style="display: flex; flex: 0 0 auto; width: 100%; justify-content: space-evenly; align-items: center;">
        <img loading="lazy" src="https://security4kmu.de/wp-content/uploads/2020/06/iais.svg" alt="" width="auto" height="50" />
        <img loading="lazy" src="assets/img/MittelstandDigital_Single.png" class="wp-image-271" width="auto" height="50" />
        <img loading="lazy" src="https://security4kmu.de/wp-content/uploads/2020/06/Logo_IT-Sicherheit.png" alt="" class="wp-image-52" srcset="https://security4kmu.de/wp-content/uploads/2020/06/Logo_IT-Sicherheit.png 560w, https://security4kmu.de/wp-content/uploads/2020/06/Logo_IT-Sicherheit-300x75.png 300w"
        sizes="(max-width: 560px) 100vw, 560px" width="auto" height="50" />
        <img loading="lazy" src="assets/img/bmwi_alpha.png" class="wp-image-271" width="auto" height="150" />
        <img style="margin: 10px;" loading="lazy" src="https://security4kmu.de/wp-content/uploads/2020/06/ZeMA-Logo-Unterzeile_RGB-1024x470.png" alt="" class="wp-image-245" srcset="https://security4kmu.de/wp-content/uploads/2020/06/ZeMA-Logo-Unterzeile_RGB-1024x470.png 1024w, https://security4kmu.de/wp-content/uploads/2020/06/ZeMA-Logo-Unterzeile_RGB-300x138.png 300w, https://security4kmu.de/wp-content/uploads/2020/06/ZeMA-Logo-Unterzeile_RGB-768x353.png 768w, https://security4kmu.de/wp-content/uploads/2020/06/ZeMA-Logo-Unterzeile_RGB-1536x706.png 1536w, https://security4kmu.de/wp-content/uploads/2020/06/ZeMA-Logo-Unterzeile_RGB-2048x941.png 2048w, https://security4kmu.de/wp-content/uploads/2020/06/ZeMA-Logo-Unterzeile_RGB-1200x551.png 1200w, https://security4kmu.de/wp-content/uploads/2020/06/ZeMA-Logo-Unterzeile_RGB-1980x910.png 1980w"
            sizes="(max-width: 1024px) 100vw, 1024px" width="auto" height="60" />
    </div>
</mat-toolbar>
<mat-toolbar  class="footer">
  <mat-nav-list class="list-horizontal">
   <a mat-list-item><a class= "list" href="https://security4kmu.de/?page_id=178" target="_blank">Impressum</a></a>
    <a mat-list-item><a class= "list"  href="https://security4kmu.de/?page_id=3" target="_blank">Datenschutz</a></a>
    <a mat-list-item><a class= "list"  href="https://security4kmu.de/?page_id=9" target="_blank">Kontakt</a></a>
</mat-nav-list>
</mat-toolbar>
