import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AuthService {

  private readonly authState = new BehaviorSubject<AuthState>({loggedIn: false, uuid: null, profile: null});

  constructor(
    private keycloakService: KeycloakService) {
  }
  
  public handleAuthSuccess() {
    const userDetails: KeycloakTokenParsed = this.keycloakService.getKeycloakInstance().idTokenParsed;
    this.keycloakService.loadUserProfile().then(profile => {
      this.authState.next({
        loggedIn: true, 
        uuid: userDetails.uuid, 
        profile: profile
      } as AuthState);
    })
  } 

  public handleLogout() {
    this.authState.next({
      loggedIn: false,  
      profile: null
    } as AuthState)
  } 

  public get authState$() : Observable<AuthState> {
    return this.authState.asObservable();
  }

  public getUUID(): string {
    return this.authState.getValue().uuid;
  }

  public isLoggedIn() : boolean {
    return this.authState.value.loggedIn;
  }

  public loginAfterRedirect(path: string) : Promise<void> {
    return this.keycloakService.login({
      redirectUri: window.location.origin + path
    });
  }

  public register() : Promise<void> {
    return this.keycloakService.register();
  }

  public logout() : Promise<void> {
    return this.keycloakService.logout(window.location.origin);
  }

  public getRoles(): string[] {
    return this.keycloakService.getUserRoles();
  }

  public getLoggedInUsername(): string {
    return this.authState.value.profile.username;
  }
}

export interface AuthState {
  loggedIn: boolean,
  uuid: string,
  profile: KeycloakProfile
}