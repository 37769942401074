<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css">

<div id="scroll" class="container" style="height: 100%;">

    <app-base-question [question]="QUESTION" [instruction]="INSTRUCTION" [info]="INFO"></app-base-question>

    <div style="display: flex; justify-content: center">
        <app-checklist-control></app-checklist-control>
        <div style="display: flex; width: 1084px; flex-direction: column; height: 100%;">
            <app-checklist-massnahmen></app-checklist-massnahmen>
        </div>
    </div>

    <swal #loginAlert *ngIf="!isLoggedIn()"
        position="top-right"
        text="Registrieren Sie sich kostenlos, um Ihr Profil zu speichern und dauerhaft mit den Daten arbeiten zu können."
        showCancelButton="true"
        (confirm)="login()">
        <ng-container *swalPortal="swalTargets.confirmButton">
            Registrieren
        </ng-container>
        <ng-container *swalPortal="swalTargets.cancelButton">
            Schließen
        </ng-container>
    </swal>

    <app-footer></app-footer>
</div>

<button class="fab" *ngIf="windowScrolled" (click)="scrollToTop()">
  <i class="fa fa-arrow-up"></i>
  </button>
