import { Component, OnInit, OnDestroy} from '@angular/core';
import {
  MassnahmeFilter,
  MassnahmeGruppierung,
  MassnahmeService,
} from '../../../services/massnahme.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ChecklistControllerService} from 'build/openapi';
import { AppService } from 'src/app/services/app.service';
import { ChartOptions } from 'chart.js';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-checklist-control',
  templateUrl: './checklist-control.component.html',
  styleUrls: ['./checklist-control.component.css'],
})
export class ChecklistControlComponent implements OnInit{
  // Pie
  public pieChartOptions: ChartOptions<'pie'> = {
    responsive: false,
    plugins: {
      tooltip: {
          callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (context.label == 'unbearbeitet'){
                  label += `${context.parsed} nicht bearbeitet`;
                }
                else {
                  label += `${context.label} von ${context.parsed} bearbeitet`;
                }
                return label;
              }
          }
      }
  }
  };

  public pieChartLabels = ['ISMS: Sicherheitsmanagement', 'ORP: Organisation und Personal', 'CON: Konzeption und Vorgehensweise', 'OPS: Betrieb', 'DER: Detektion und Reaktion', 'APP: Anwendungen', 'SYS: IT-Systeme', 'IND: Industrielle IT', 'NET: Netze und Kommunikation', 'INF: Infrastruktur', 'ICS: Industrielle Automatisierungssysteme', 'unbearbeitet'];

  public pieChartDatasets = [
    {data: [0,0,0,0,0,0,0,0,0,0,0,0]}
  ];
  public pieChartLegend = false;
  public pieChartPlugins = [];

  selectedGruppierung = MassnahmeGruppierung.BAUSTEIN;

  rollenList: string[];
  bedrohungenList: string[];

  filterForm: FormGroup;

  checkboxSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private appService: AppService,
    public massnahmeService: MassnahmeService,
    private checklistControllerService: ChecklistControllerService
  ) {}

  ngOnInit(): void {
    this.rollenList =  this.massnahmeService.rollen;
    this.bedrohungenList = this.massnahmeService.bedrohungen;
    this.filterForm = this.fb.group({
      searchTerm: [''],
      rollen: [[]],
      bedrohungen: [[]],
      anwendungsbereiche: this.fb.array(
        this.massnahmeService.anwendungsbereiche.filter(this.entferneSonstigeOption).map(() =>
          this.fb.control(false)
        )
      ),
      kategorien: this.fb.array(
        this.massnahmeService.kategorien.filter(this.entferneSonstigeOption).map(() => this.fb.control(false))
      ),
      kritikalitaeten: this.fb.array(
        this.massnahmeService.kritikalitaeten.filter(this.entferneSonstigeOption).map(() => this.fb.control(false))
      ),
      aufwaende:  this.fb.array(
        this.massnahmeService.aufwaende.filter(this.entferneSonstigeOption).map(() => this.fb.control(false))
      ),
      din: this.fb.array(
        this.massnahmeService.din.filter(this.entferneSonstigeOption).map(() => this.fb.control(false))
      ),
      relevanz: this.fb.array(
        this.massnahmeService.relevanz.filter(this.entferneSonstigeOption).map(() => this.fb.control(false))
      ),
      comment: this.fb.array(
        this.massnahmeService.comment.filter(this.entferneSonstigeOption).map(() => this.fb.control(false))
      ),
      activeCheckboxString: this.fb.array(
        this.massnahmeService.activeCheckboxString.filter(this.entferneSonstigeOption).map(() => this.fb.control(false))
      )
    });

    this.checkboxSubscription = this.massnahmeService.sumActiveCheckboxesSubject.subscribe((updatedArray: number[]) => {
      this.updatePieChart();
    });
    const sumMassnahmen = this.massnahmeService.getSumMassnahmen();
    this.updatePieChart();
    this.pieChartOptions = {
      responsive: false,
      plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
              const zahlenwert = sumMassnahmen[context.dataIndex];
              if (context.label == 'unbearbeitet'){
                label += `${context.parsed} nicht bearbeitet`;
              }
              else {
                label += `${context.parsed} von ${zahlenwert} bearbeitet`;
              }
              return label;
            }
          }
        }
      }
    };

    this.applyFilter();
    this.gruppierungSelected(this.selectedGruppierung);
  }

  ngOnDestroy() {
    this.checkboxSubscription.unsubscribe();
  }

  private entferneSonstigeOption = (e: string) => e !== 'Sonstige';

  entferneRolle(rolle: string) {
    this.filterForm.patchValue({'rollen': this.filterForm.value.rollen.filter(r => r !== rolle)});
    this.massnahmeService.applyFilterInput(this.filter);


  }

  entferneBedrohung(bedrohung: string) {
    this.filterForm.patchValue({'bedrohungen': this.filterForm.value.bedrohungen.filter(b => b !== bedrohung)});
    this.massnahmeService.applyFilterInput(this.filter);
  }

  gruppierungOptionen(): MassnahmeGruppierung[] {
    return [
      MassnahmeGruppierung.BAUSTEIN,
      MassnahmeGruppierung.KRITIKALITÄT,
      MassnahmeGruppierung.ANWENDUNGSBEREICH,
      MassnahmeGruppierung.KATEGORIE,
      MassnahmeGruppierung.AUFWAND,
    ];
  }

  gruppierungSelected(gruppierung: MassnahmeGruppierung) {
    this.massnahmeService.gruppiereMassnahmen(gruppierung);
    this.massnahmeService.storeGruppierung(gruppierung);
  }

  applyFilter() {
    this.massnahmeService.applyFilterInput(this.filter);
    this.massnahmeService.storeFilter();
  }

  resetTextFilter() {
    this.filterForm.patchValue({'searchTerm': ''});
    this.applyFilter();
  }

  updatePieChart() {
    var data = this.massnahmeService.getSumCheckboxes();
    this.pieChartDatasets = [{
      data: data
    }];
  }

  exportAsPdf() {
    this.checklistControllerService.exportAsPdf(this.appService.getUUID()).subscribe(data => {
      const fileURL = URL.createObjectURL(data);
      window.open(fileURL, '_blank');
    });
  }

  get filter(): MassnahmeFilter {
    return new MassnahmeFilter(
      this.filterForm.value.searchTerm,
      this.filterForm.value.rollen,
      this.filterForm.value.bedrohungen,
      this.indexes(this.filterForm.value.kritikalitaeten).map((index) => this.massnahmeService.kritikalitaeten[index]),
      this.indexes(this.filterForm.value.anwendungsbereiche).map((index) => this.massnahmeService.anwendungsbereiche[index]),
      this.indexes(this.filterForm.value.kategorien).map((index) => this.massnahmeService.kategorien[index]),
      this.indexes(this.filterForm.value.aufwaende).map((index) => this.massnahmeService.aufwaende[index]),
      this.indexes(this.filterForm.value.din).map((index) => this.massnahmeService.din[index]),
      this.indexes(this.filterForm.value.relevanz).map((index) => this.massnahmeService.relevanz[index]),
      this.indexes(this.filterForm.value.comment).map((index) => this.massnahmeService.comment[index]),
      this.indexes(this.filterForm.value.activeCheckboxString).map((index) => this.massnahmeService.activeCheckboxString[index])
    );
  }

  get rollen(): FormArray {
    return this.filterForm.get('rollen') as FormArray;
  }

  get anwendungsbereiche(): FormArray {
    return this.filterForm.get('anwendungsbereiche') as FormArray;
  }

  get kategorien(): FormArray {
    return this.filterForm.get('kategorien') as FormArray;
  }

  get kritikalitaeten(): FormArray {
    return this.filterForm.get('kritikalitaeten') as FormArray;
  }

  get bedrohungen(): FormArray {
    return this.filterForm.get('bedrohungen') as FormArray;
  }

  get aufwaende(): FormArray {
    return this.filterForm.get('aufwaende') as FormArray;
  }


  private indexes(data: string[]) {
    if (!data) {
      return [];
    }
    return data.reduce(function (a, e, i) {
      if (e) {
        a.push(i);
      }
      return a;
    }, []);
  }

  isLoggedIn() : boolean {
    return this.appService.isLoggedIn();
  }
}

