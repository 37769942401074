<div class="content-component" >
    <mat-form-field appearance="outline">
      <mat-label>Wählen Sie aus</mat-label>
      <mat-select [(ngModel)]="selectedOptionIndex" (selectionChange)="toggle_button()" placeholder="Wählen Sie aus">
        <mat-option *ngFor="let option of options; let i = index" [value]="i">{{ option }}</mat-option>
      </mat-select>
      <mat-hint>{{question}}</mat-hint>
    </mat-form-field>

    <div *ngIf="getSelectedTextField()" style="margin-top: 1rem;">
      <mat-form-field appearance="outline">
        <mat-label>Hier Ihre Branche eintragen</mat-label>
        <input matInput [(ngModel)]="text" (focusin)="onFocusIn()" (focusout)="onFocusOut()" required>
      </mat-form-field>
    </div>
</div>
