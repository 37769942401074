import { NgModule } from '@angular/core';
import { RouteService } from './services/route.service';
import { Routes, RouterModule } from '@angular/router';
import { EnterAfterLoginComponent } from './components/enter-after-login/enter-after-login.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { BusinessComponent } from './components/business/business.component';
import { BausteineComponent } from './components/bausteine/bausteine.component';
import { ExitPageComponent } from './components/exit-page/exit-page.component';
import { SelectServiceCriticalityComponent } from './components/services/select-service-criticality/select-service-criticality.component';
import { SelectServiceErpComponent } from './components/services/select-service-erp/select-service-erp.component';
import { SelectServiceProvidersComponent } from './components/services/select-service-providers/select-service-providers.component';
import { SelectServicesComponent } from './components/services/select-services/select-services.component';
import { ChecklistComponent } from './components/checklist/checklist.component';
import { MassnahmenGuard } from './guards/massnahmen.guard';
import { AppGuard } from './guards/app.guard';
import { BausteineGuard } from './guards/bausteine.guard';
import { FeedbackReaderComponent } from './components/feedback-reader/feedback-reader.component';
import { GlossaryComponent } from './components/glossary/glossary.component';

const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'home', component: LandingPageComponent },
  { path: 'enter', component: EnterAfterLoginComponent },
  { path: RouteService.getRouteKey("LandingPageComponent"), component: LandingPageComponent, data: { animation: RouteService.getIndex("LandingPageComponent") } },
  { path: RouteService.getRouteKey("BusinessComponent"), component: BusinessComponent, data: { animation: RouteService.getIndex("BusinessComponent")}, canActivate : [AppGuard]},
  { path: RouteService.getRouteKey("SelectServicesComponent"), component: SelectServicesComponent, data: { animation: RouteService.getIndex("SelectServicesComponent") }, canActivate : [AppGuard] },
  { path: RouteService.getRouteKey("SelectServiceErpComponent"), component: SelectServiceErpComponent, data: { animation: RouteService.getIndex("SelectServiceErpComponent") }, canActivate : [AppGuard] },
  { path: RouteService.getRouteKey("SelectServiceProvidersComponent"), component: SelectServiceProvidersComponent, data: { animation: RouteService.getIndex("SelectServiceProvidersComponent") }, canActivate : [AppGuard] },
  { path: RouteService.getRouteKey("SelectServiceCriticalityComponent"), component: SelectServiceCriticalityComponent, data: { animation: RouteService.getIndex("SelectServiceCriticalityComponent") }, canActivate : [AppGuard] },
  { path: RouteService.getRouteKey("BausteineComponent"), component: BausteineComponent, data: { animation: RouteService.getIndex("BausteineComponent") }, canActivate : [AppGuard, BausteineGuard] },
  { path: RouteService.getRouteKey("ChecklistComponent"), component: ChecklistComponent, data: { animation: RouteService.getIndex("ChecklistComponent") }, canActivate : [AppGuard, MassnahmenGuard] },
  { path: RouteService.getRouteKey("ExitPageComponent"), component: ExitPageComponent, data: { animation: RouteService.getIndex("ExitPageComponent") } },
  { path: RouteService.getRouteKey("FeedbackReaderComponent"), component: FeedbackReaderComponent, data: {animation: RouteService.getIndex("FeedbackReaderComponent")}},
  { path: RouteService.getRouteKey("GlossaryComponent"), component: GlossaryComponent, data: { animation: RouteService.getIndex("GlossaryComponent") }, canActivate : [AppGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
