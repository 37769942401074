<mat-toolbar color="primary" style="flex: 0 0 auto;" class="mat-elevation-z4">
    <img style="position: relative; left: 0; right: 0; margin: auto; height: 100%; width: auto; cursor: pointer;" src="https://security4kmu.de/wp-content/uploads/2020/06/GSPA_Logo.png" class="custom-logo" alt="GrundschutzPLUS Aktivator" srcset="https://security4kmu.de/wp-content/uploads/2020/06/GSPA_Logo.png 501w, https://security4kmu.de/wp-content/uploads/2020/06/GSPA_Logo-300x93.png 300w"
        sizes="(max-width: 501px) 100vw, 501px" width="350" height="77" (click)="toHome()">
    <div style="display: flex; flex-direction: column; align-items: flex-end;">
        <div>
            <button type="button" aria-label="Login" mat-icon-button (click)="toLogin()" *ngIf="!loggedIn">
                <mat-icon svgIcon="login" aria-label="Login">Login</mat-icon>
            </button>
            <button type="button" aria-label="Logout" mat-icon-button (click)="toLogout()" *ngIf="loggedIn">
                <mat-icon svgIcon="logout" aria-label="You are logged in">User
                </mat-icon>
            </button>
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="true">
                <mat-icon svgIcon="menu" aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
        </div>
        <span class="loggedInUserInfo">{{loggedInUserInfo}}</span>
    </div>
</mat-toolbar>
<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport [mode]="'over'" [attr.role]="'navigation'" position="end">
        <mat-toolbar style="display: flex; justify-content: space-between;">
            <div>
                Menu
            </div>
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="true">
                <mat-icon svgIcon="close" aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
        </mat-toolbar>
        <mat-nav-list>
            <a mat-list-item [routerLink]="'home'" routerLinkActive="active">Home</a>
            <a mat-list-item (click)="toLogin()" routerLinkActive="active" *ngIf="!loggedIn">Login</a>
            <a mat-list-item (click)="toLogout()" routerLinkActive="active" *ngIf="loggedIn">Logout</a>
            <a mat-list-item [routerLink]="'glossary'" routerLinkActive="glossary">Glossar</a>
            <!-- <a mat-list-item [routerLink]="'Glossary'" routerLinkActive="active">Glossary</a> -->
            <a mat-list-item href="https://security4kmu.de/?page_id=9" routerLinkActive="active" target="_blank">Kontakt</a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content style="flex: 1 1 auto; min-height: 0; min-width: 0; max-width: 100%; display: flex; flex-direction: column;">
        <div class="main-container" [@routeAnimations]="prepareRoute(outlet)" (@routeAnimations.done)="routeAnimationDone()">
            <app-stepper></app-stepper>
            <router-outlet #outlet="outlet"></router-outlet>
            <button mat-fab class="float-feedback" (click)="openFeedbackDialog()">
                <mat-icon svgIcon="feedback" [ngStyle]="{'color': 'white'}"></mat-icon>
            </button>

        </div>
    </mat-sidenav-content>
    <!-- <swal #duplicateUUIDAlert *ngIf="isLoggedIn()">
      <div *ngIf="checkIfLastLoginBeforChanges()">
        text="Mit Ihrem Benutzeraccount wurde bereits ein Profil (Daten zu Ihrem Unternehmen und Ihren IT-Services) angelegt. Soll dieses geladen werden? Das aktuelle Profil wird dann gelöscht. Andernfalls wird das gespeicherte Profil mit den aktuellen Daten überschrieben."
        showCancelButton="true">
        <ng-container *swalPortal="swalTargets.confirmButton">
          Profil aus dem Account laden
        </ng-container>
        <ng-container *swalPortal="swalTargets.cancelButton">
          Gespeichertes Profil überschreiben
        </ng-container>
      </div>
      <div *ngIf="!checkIfLastLoginBeforChanges()">
        text="Mit Ihrem Benutzeraccount wurde bereits ein Profil (Daten zu Ihrem Unternehmen und Ihren IT-Services) angelegt. Aufgrund von umfangreichen Änderungen an unserem Tool müssen wir Sie bitten, die Fragen neu zu beantworten."
        showCancelButton="true">
        <ng-container *swalPortal="swalTargets.cancelButton">
          Fortfahren
        </ng-container>
      </div>
    </swal> -->
    <swal #duplicateUUIDAlert *ngIf="isLoggedIn()"
        text="Mit Ihrem Benutzeraccount wurde bereits ein Profil (Daten zu Ihrem Unternehmen und Ihren IT-Services) angelegt. Soll dieses geladen werden? Das aktuelle Profil wird dann gelöscht. Andernfalls wird das gespeicherte Profil mit den aktuellen Daten überschrieben."
        showCancelButton="true">
        <ng-container *swalPortal="swalTargets.confirmButton">
            Profil aus dem Account laden
        </ng-container>
        <ng-container *swalPortal="swalTargets.cancelButton">
            Gespeichertes Profil überschreiben
        </ng-container>
    </swal>
</mat-sidenav-container>

