import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FeedbackControllerService } from 'build/openapi';
import { Observable, interval } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

export interface FeedbackData {
  id: number;
  key: string;
  feedback: string;
  date: string;
}


@Component({
  selector: 'app-feedback-reader',
  templateUrl: './feedback-reader.component.html',
  styleUrls: ['./feedback-reader.component.css'],
  providers: [FeedbackControllerService]
})
export class FeedbackReaderComponent implements OnInit {
  displayedColumns: string[] = ['number', 'key', 'feedback', 'date'];
  dataSource: MatTableDataSource<FeedbackData>;
  keyMap: { [key: string]: string } = {
    "": "Landing Page",
    "business": "Unternehmen",
    "services": "Serviceauswahl",
    "service-erp": "ERP-Auswahl",
    "service-providers": "Servicebetrieb",
    "services-criticality": "Servicekritikalität",
    "bausteine": "Bausteine",
    "checklist": "Checklist",
    "exit-paget": "Exit Page",
    "sdjasfjeu78dtuoieatgwrutoir56467z3z648et368735z467": "feedback"
  };
  constructor(private feedbackService: FeedbackControllerService) { }

  ngOnInit() {
    const pollInterval = 5000; // 5 seconds
    const poll$ = interval(pollInterval).pipe(
      startWith(0),
      switchMap(() => this.feedbackService.getAllFeedback())
    );
    poll$.subscribe(data => {
      this.dataSource = new MatTableDataSource(data.map((feedback, index) => ({
        id: index + 1,
        key: this.updateKey(feedback.key),
        feedback: feedback.value,
        date: feedback.date
      })));
    });
  }

  updateKey(key: string): string {
    if (this.keyMap[key]) {
      return this.keyMap[key];
    }
    return key;
  }
}
