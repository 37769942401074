import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Options } from '../../../model/options';
import { OptionSelection } from '../../../model/option-selection';
import { AppService } from '../../../services/app.service';
import { ServiceService } from '../../../services/service.service';
import { startWith } from 'rxjs/operators';


@Component({
  selector: 'app-service-view',
  templateUrl: './service-view.component.html',
  styleUrls: ['./service-view.component.css']
})
export class ServiceViewComponent implements OnInit {
  @Input() name: string;
  @Input() key: string;
  @Input() options: Options;
  @Input() defaultOption: number;
  @Input() description: string;
  @Input() color: string;
  @Input() serviceKey: string;
  @Input() nameShort: string;

  private static readonly DEFAULT_STYLE = "empty";
  private static readonly KeyPrefix: string = 'SERVICE_';

  public static readonly SelectionKey: string = 'SERVICES';

  public style: string = ServiceViewComponent.DEFAULT_STYLE;
  public selectedOptionIndex: number = -1;

  public overarchingSelectable: boolean = false;
  public isoverarchingService: boolean = false;

  public disabled: boolean = true;
  private overarchingDisabled: boolean = true;

  subscriptionAllSelection: Subscription;

  constructor( private appService: AppService, private serviceService: ServiceService) {}

  ngOnInit(): void {
    this.overarchingSelectable = this.key == "SERVICES_CRITICALITY" ? true : false;
    this.subscribe();
    this.isoverarchingService = this.serviceKey.includes('overarching') ? true : false;
    this.checkIfActive();
    this.loadValue();
    // this.storeValue();
    this.updateStyle();
  }

  ngOnDestroy() { this.subscriptionAllSelection.unsubscribe(); }

  private loadValue() {
    const data = this.appService.getUserData(ServiceViewComponent.KeyPrefix + this.key + ':' + this.serviceKey);
    if(data && data.value) {
      this.selectedOptionIndex = Number(this.options.array.findIndex(o => o.value === data.value));
    }
    else {this.selectedOptionIndex = this.defaultOption;}
    this.onOptionSelected(this.selectedOptionIndex);
  }

  private storeValue() {
    this.appService.sendUserData(ServiceViewComponent.KeyPrefix + this.key + ':' + this.serviceKey, this.options.array[this.selectedOptionIndex].value);
  }

  private checkIfActive() {
    if (!this.isoverarchingService) {
      if (this.serviceKey.startsWith('ot_')) {
        this.serviceService.updateSelectedOptionsOt(this.serviceKey, (this.appService.getUserData(ServiceViewComponent.KeyPrefix + 'SERVICES' + ':' + this.serviceKey)?.value == "2"));
      }
      else {
        this.serviceService.updateSelectedOptionsIt(this.serviceKey, (this.appService.getUserData(ServiceViewComponent.KeyPrefix + 'SERVICES' + ':' + this.serviceKey)?.value == "2"));
      }
    }
  }

  private updateStyle() {
    if(!this.isDisabled()) {
      if( this.selectedOptionIndex >= 0 && this.selectedOptionIndex < this.options.array.length ) {
        this.style = this.options.array[this.selectedOptionIndex].style;
      }
      else {
        this.style = ServiceViewComponent.DEFAULT_STYLE;
      }
    }
  }

  private subscribe() {
    if ( this.serviceKey.includes('overarching') && !this.overarchingSelectable ) {
      if (this.serviceKey.includes('it_overarching')) {
        this.subscriptionAllSelection = this.serviceService.selectedOptionsIt$.pipe(
          startWith(null)
        ).subscribe(() => {
          const itSelected = this.serviceService.ifItSelected() ? 0 : 2;
          this.overarchingDisabled = itSelected === 0 ? false : true;
          this.onOptionSelected(itSelected);
        });
      } else {
        this.subscriptionAllSelection = this.serviceService.selectedOptionsOt$.pipe(
          startWith(null)
        ).subscribe(() => {
          const otSelected = this.serviceService.ifOtSelected() ? 0 : 2;
          this.overarchingDisabled = otSelected === 0 ? false : true;
          this.onOptionSelected(otSelected);
        });
      }

    }
    else if( this.serviceKey.includes('overarching') && this.overarchingSelectable ) {
      if (this.serviceKey.includes('it_overarching')) {
        this.subscriptionAllSelection = this.serviceService.allOverarchingItSelectionSource$.subscribe((selection: OptionSelection) => {
          this.onOptionSelected(selection.index);
        });
      }
      else {
        this.subscriptionAllSelection = this.serviceService.allOverarchingOtSelectionSource$.subscribe((selection: OptionSelection) => {
          this.onOptionSelected(selection.index);
        });
      }
    }
    else {
      if ( this.serviceKey.startsWith('ot_') ) {
        this.subscriptionAllSelection = this.serviceService.allOtSelectionSource$.subscribe((selection: OptionSelection) => {
          this.onOptionSelected(selection.index);
        });
      } else {
        this.subscriptionAllSelection = this.serviceService.allItSelectionSource$.subscribe((selection: OptionSelection) => {
          this.onOptionSelected(selection.index);
        });
      }
    }
  }

  public isDisabled() : boolean {
    if( this.key != "SERVICES") {
      this.disabled = !(this.appService.getUserData(ServiceViewComponent.KeyPrefix + ServiceViewComponent.SelectionKey + ':' + this.serviceKey)?.value == "2");
    }
    else { this.disabled = this.isoverarchingService ? this.overarchingDisabled : false; }
    return this.disabled;
  }

  public onOptionSelected(index: number) {
    this.selectedOptionIndex = index;
    const isSelected = this.selectedOptionIndex == 0 ? true : false;
    if ( !this.serviceKey.includes('overarching') && this.key == "SERVICES") {
      if (this.serviceKey.startsWith('ot_')) {
        this.serviceService.updateSelectedOptionsOt(this.serviceKey, isSelected);
      }
      else {
        this.serviceService.updateSelectedOptionsIt(this.serviceKey, isSelected);
      }
    }
    this.updateStyle();
    this.storeValue();
  }
}
