import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BausteinService } from '../../services/baustein.service';
import { bausteinClasses } from '../../model/baustein-classes';
import { RouteService } from '../../services/route.service';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { Texts } from '../../texts';
import { AppService } from '../../services/app.service';
import { BausteinDto } from 'build/openapi';

@Component({
  selector: 'app-bausteine',
  templateUrl: './bausteine.component.html',
  styleUrls: ['./bausteine.component.scss']
})
export class BausteineComponent implements OnInit {
  readonly QUESTION = "Auf dieser Seite sehen Sie alle Bausteine des BSI IT-Grundschutz Kompendiums aufgelistet.";
  readonly INSTRUCTION = "Diese Seite gibt Ihnen einen Überblick der für Sie relevanten Bausteine. "+Texts.MORE_INFO;
  readonly INFO = {
    title: "Bausteine",
    content: `
  <p>Diese Seite ist f&uuml;r Sie als Information gedacht. Sie sehen f&uuml;r Sie relevante IT-Sicherheitsbausteine aus bekannten IT-Sicherheits-Standards, wie z.B. dem BSI IT-Grundschutz. Die Bausteine liefern Ihnen einen kurzen &Uuml;berblick &uuml;ber Anforderungen und Ma&szlig;nahmen zum entsprechenden Thema wenn Sie sie anklicken. Die hervorgehobenen Bausteine sind f&uuml;r Sie relevant und wurden aus den von Ihnen ausgew&auml;hlten Services ermittelt.</p>
<p>F&uuml;r genauere Informationen und konkrete Handlungsempfehlungen klicken Sie &ldquo;Weiter&rdquo; um zur Checklist zu gelangen.</p>
  `,
  }
  showAllBausteine : boolean;

  constructor(
    private appService: AppService,
    private bausteinService: BausteinService,
    private cdr: ChangeDetectorRef,
    private route: RouteService) {
    this.showAllBausteine = bausteinService.allBausteine;
    if (!this.appService.getUserData('BAUSTEINE_VISITED')) {
      this.appService.sendUserData('BAUSTEINE_VISITED', 'true')
    }
  }

  ngOnInit(): void {
    this.route.className = "BausteineComponent";
  }

  getBausteine(key: string): BausteinDto[] {
    return this.bausteinService.getBausteine(key);
  }

  getBlockTitle(blockKey: string) {
    return bausteinClasses.get(blockKey).title;
  }

  getPrimaryColor(blockKey: string) {
    return bausteinClasses.get(blockKey).colorPri;
  }

  getSecondaryColor(blockKey: string) {
    return bausteinClasses.get(blockKey).colorSec;
  }

  navigate_prev(): void {
    this.route.routePrevName();
  }

  navigate_next(): void {
    this.route.routeNextName();
  }

  showAllBausteineChanged(event: MatSlideToggleChange) {
    this.bausteinService.setAllBausteine(event.checked);
    this.cdr.detectChanges();
  }

  getWidth(key: string) {
    const bausteine = this.bausteinService.getBausteine(key);
    return bausteine ? bausteine.length * 55 + 'px' : 0;
  }
}
