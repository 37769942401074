import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-enter-after-login',
  template: '<mat-progress-bar mode="indeterminate" *ngIf="showProgress"></mat-progress-bar>'
})
export class EnterAfterLoginComponent implements OnInit {

  showProgress = true;

  constructor(
    private appService: AppService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.authService.authState$.subscribe(authState => {
      if (this.authService.isLoggedIn()) {
        this.appService.nivigateToLastVisitedStep();
      }
    });
  }

}
