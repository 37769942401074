import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogInstructionComponent } from 'src/app/components/shared/dialog-instruction/dialog-instruction.component';


@Component({
  selector: 'app-base-question',
  templateUrl: './base-question.component.html',
  styleUrls: ['./base-question.component.css']
})
export class BaseQuestionComponent implements OnInit {
  readonly DISPLAY_LENGTH: number = 200;
  @Input() question: string = null;
  @Input() info: any = null;
  @Input() instruction: string = null;

  expanded: boolean = false;
  iShort: string = null
  iMore: string = null

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    if (this.instruction != null) {
      if (this.instruction.length > this.DISPLAY_LENGTH) {
        let split: string = this.instruction.substring(0, this.DISPLAY_LENGTH - 1)
        this.iShort = split.substring(0, split.lastIndexOf(' '))
        this.iShort += "..."
        this.iMore = split.substring(split.lastIndexOf(' '))
        this.iMore += this.instruction.substring(this.DISPLAY_LENGTH - 1)
      }
    }
  }
  openDialog() {
    this.dialog.open(DialogInstructionComponent, { data: this.info });
  }
}

