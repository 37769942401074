import { Injectable, Type } from '@angular/core';
import { FeedbackControllerService, FeedbackDto } from 'build/openapi';
import { ResolveEnd, Router } from '@angular/router';
import { AppService } from './app.service';
import { FeedbackDialogComponent } from '../components/feedback-dialog/feedback-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class FeedBackService {

  private currentComponentName: string = '';

  constructor(
    private router: Router,
    private appService: AppService,
    private feedbackControllerService: FeedbackControllerService) {
    this.router.events.subscribe((event) => {
      if (event instanceof ResolveEnd) {
        this.currentComponentName = event.urlAfterRedirects.split('/').pop();
      }
    });
  }

  public openFeedbackDialog(dialog: MatDialog): void {
    dialog.open(FeedbackDialogComponent);
  }

  public sendFeedback(text: string) {
    if (this.appService.isLoggedIn()) {
      this.feedbackControllerService.saveFeedback(this.getFeedback(text)).subscribe(() => {
      });
    } else {
      this.feedbackControllerService.saveFeedbackAnonymous(this.appService.getUUID(), this.getFeedback(text)).subscribe(() => {
      });
    }
  }

  private getFeedback(text: string) : FeedbackDto {
    return {
      key: this.currentComponentName,
      value: text
    }
  }
}
