import { Injectable } from '@angular/core';
import { MassnahmeDto, UserData } from 'build/openapi';
import { MassnahmeControllerService } from 'build/openapi/api/massnahmeController.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MassnahmeUi } from '../model/checklist-ui';
import { AppService } from './app.service';
import Fuse from 'fuse.js'
import { Observable, Subject, BehaviorSubject, Subscriber, Subscription } from 'rxjs';
import { BausteinUi } from '../model/checklist-ui';
import { filter, tap } from 'rxjs/operators';
import { ChecklistCommentComponent } from '../components/checklist/checklist-comment/checklist-comment.component';
@Injectable({
  providedIn: 'root'
})
export class MassnahmeService {

  private fuseOptions = {
    threshold: 0.0,
    ignoreLocation: true,
    includeMatches: true,
    keys: ['key', 'source', 'textLong', 'title', 'type', 'category', 'roles', 'threats', 'criticality', 'effort', 'comment', 'din', 'relevanz', 'activeCheckboxString']
  }

  private fuse: Fuse<MassnahmeDto>;
  private data: MassnahmeDto[];
  private datasource: BehaviorSubject<MassnahmeServiceMessage> = new BehaviorSubject<MassnahmeServiceMessage>({ massnahmen: [], type: null, showFiltered: false });
  private gruppierung: Subject<MassnahmeGruppierung> = new Subject<MassnahmeGruppierung>();

  private sumMassnahmen: number = 0;
  private sumActiveCheckboxes: number[] = [0,0,0,0,0,0,0,0,0,0,0];
  public sumActiveCheckboxesSubject: Subject<number[]> = new Subject<number[]>();

  massnahmen: MassnahmeDto[] = [];
  bausteine: BausteinUi[];
  aufwaende: string[] = [];
  kategorien: string[] = [];
  kritikalitaeten: string[] = [];
  anwendungsbereiche: string[] = [];
  bedrohungen: string[] = [];
  rollen: string[] = [];
  din: string[] = ["DIN", "empty"];
  relevanz: string[] = ["empty", "RELEVANT"];
  activeCheckboxString: string[] = ['true', 'false'];
  comment: string[] = ["COMMENT", "empty"];
  filter: MassnahmeFilter = new MassnahmeFilter();

  private massnahmenGeladen = new Subject<boolean>();
  private massnahmenChecklistUserGeladen = new Subject<boolean>();

  constructor(
    private appService: AppService,
    private massnahmeControllerService: MassnahmeControllerService,
    private dialog: MatDialog) {}

  public ladeMassnahmen(): Observable<boolean> {
    this.massnahmeControllerService.getAllMassnahmen().subscribe(data => {
      this.massnahmen = data;
      this.bausteine = this.ladeBausteine();
      this.aufwaende = this.ladeAufwaende();
      this.anwendungsbereiche = this.ladeAnwendungsbereiche();
      this.kategorien = this.ladeKategorien();
      this.kritikalitaeten = [ MassnahmeKritikalitaet.GERING, MassnahmeKritikalitaet.MITTEL, MassnahmeKritikalitaet.HOCH, MassnahmeKritikalitaet.SONSTIGE ]
      this.bedrohungen = this.ladeBedrohungen();
      this.rollen = this.ladeRollen();
      this.massnahmenGeladen.next(true);
    });
    return this.massnahmenGeladen.asObservable();
  }

  public ladeMassnahmenChecklistUser(): Observable<boolean> {
    if (this.appService.isLoggedIn()) {
      this.massnahmeControllerService.getMassnahmenChecklist().subscribe((data: Array<MassnahmeDto>) => {
        this.handleGeladeneMassnahmen(data);
      });
    }
    else {
      this.massnahmeControllerService.getMassnahmenChecklistAnonymous(this.appService.getUUID()).subscribe((data: Array<MassnahmeDto>) => {
        this.handleGeladeneMassnahmen(data);
      });
    }
    return this.massnahmenChecklistUserGeladen.asObservable();
  }

  private handleGeladeneMassnahmen(data: MassnahmeDto[]) {
    this.data = data.sort((a, b) => {
      let a_s = a.key.split(".")
      let b_s = b.key.split(".")
      for (let i = 0; i < Math.min(a_s.length, b_s.length); ++i) {
        let l = a_s[i];
        let r = b_s[i];
        let nl: number = +l;
        let nr: number = +r;
        let res: number = 0;
        if (!isNaN(nl) && !isNaN(nr)) {
          res = nl - nr;
        } else {
          res = l.localeCompare(r);
        }
        if (res != 0) {
          return res;
        }
      }
      return a_s.length - b_s.length;
    });
    this.fuse = new Fuse<MassnahmeDto>(data, this.fuseOptions);
    this.datasource.next({ massnahmen: data, type: MassnahmeMessageType.INIT, showFiltered: true });
    this.massnahmenChecklistUserGeladen.next(true);
  }

  private ladeAufwaende(): string[] {
    return this.verschiebeSonstigeOption(this.massnahmen.filter(m => m.effort).map(m => m.effort).filter(this.unique));
  }

  private ladeAnwendungsbereiche(): string[] {
    return this.verschiebeSonstigeOption(this.massnahmen.filter(m => m.type).map(m => m.type).filter(this.unique));
  }

  private ladeKategorien(): string[] {
    return this.verschiebeSonstigeOption(this.massnahmen.filter(m => m.category).map(m => m.category).filter(this.unique));
  }

  private ladeBausteine() : BausteinUi[] {
    return this.massnahmen.filter(m => m.bausteinKey).map(m => new BausteinUi(m.bausteinKey, m.bausteinTitle, m.bausteinOrder));
  }

  private ladeBedrohungen(): string[] {
    return [...new Set(this.massnahmen.filter(m => m.threats).map(m => m.threats).reduce((accum, el) => accum.concat(el), []))];
  }

  private ladeRollen(): string[] {
    return [...new Set(this.massnahmen.filter(m => m.roles).map(m => m.roles).reduce((accum, el) => accum.concat(el), []))];
  }

  public alleMassnahmen() {
    if (!this.fuse) {
      return;
    }
    const index = this.fuse.getIndex() as any;
    index.docs.forEach((massnahme: MassnahmeDto) => {
      massnahme.activeCheckboxString = massnahme.activeCheckbox.toString();
    });
    this.datasource.next({ massnahmen: index.docs, type: MassnahmeMessageType.INIT, showFiltered: true });
    this.initalPieChartRead();
  }

  public get datasource$(): Observable<MassnahmeServiceMessage> {
    return this.datasource.asObservable();
  }

  public gruppiereMassnahmen(gruppierung: MassnahmeGruppierung) {
    this.gruppierung.next(gruppierung);
  }

  public storeGruppierung(gruppierung: MassnahmeGruppierung) {
    this.appService.sendUserData('CHECKLIST:' + 'Gruppinerung', gruppierung);
  }

  public get gruppierung$(): Observable<MassnahmeGruppierung> {
    return this.gruppierung.asObservable();
  }

  public applyExistingFilter() {
    const result = this.applyFilter(this.filter);
    if (result) {
      this.datasource.next({ massnahmen: result, type: MassnahmeMessageType.INIT, showFiltered: false });
    }
  }

  public applyFilterInput(filter: MassnahmeFilter) {
    this.filter = filter;
    const result = this.applyFilter(filter);
    if (result) {
      this.datasource.next({ massnahmen: result, type: MassnahmeMessageType.FILTER, showFiltered: false });
    }
  }

  private applyFilter(filter: MassnahmeFilter) {
    Object.assign(this.filter, filter);
    if (!this.filter.hasOptions()) {
      this.alleMassnahmen();
      return;
    }
    const searchTerms = [];
    this.addSearchTerm(this.filter.rollen, "roles", searchTerms);
    this.addSearchTerm(this.filter.bedrohungen, "threats", searchTerms);
    this.addSearchTerm(this.filter.kategorien, "category", searchTerms);
    this.addSearchTerm(this.filter.anwendungsbereiche, "type", searchTerms);
    this.addSearchTerm(this.filter.aufwaende, "effort", searchTerms);
    this.addSearchTerm(this.filter.kritikalitaeten, "criticality", searchTerms);
    this.addSearchTerm(this.filter.din, "din", searchTerms);
    this.addSearchTerm(this.filter.relevanz, "relevanz", searchTerms);
    this.addSearchTerm(this.filter.comment, "comment", searchTerms);
    this.addSearchTerm(this.filter.activeCheckboxString, "activeCheckboxString", searchTerms);
    return this.fuse.search(this.options(searchTerms)).map(obj => {
      return obj.item;
    });
  }

  public updateMassnahme(massnahme: MassnahmeDto) {
    const searchResults = this.fuse.search({ key: massnahme.key });
    if (searchResults.length > 0) {
      const firstResult = searchResults[0];
      const index = firstResult.refIndex;
      this.data[index] = massnahme;
      this.fuse = new Fuse<MassnahmeDto>(this.data, this.fuseOptions);
    }
  }

  private options(searchTerms: any[]) {
    if (searchTerms.length === 0) {
      return {
        $or: [
          { key: this.filter.text },
          { title: this.filter.text },
          { textLong: this.filter.text },
          { source: this.filter.text },
          { din: this.filter.text}
        ]
      }
    }
    else if (this.filter.text?.length === 0) {
      return {
        $and: searchTerms
      }
    }
    else {
      return {
        $and: [
          {
            $or: [
              { key: this.filter.text },
              { title: this.filter.text },
              { textLong: this.filter.text },
              { source: this.filter.text },
              { din: this.filter.text}
            ]
          },
          { $and: searchTerms }
        ]
      }
    }
  }

  private addSearchTerm(data: string[], key: string, searchTerms: any[]) {
    if (data?.length === 0) {
      return;
    }
    searchTerms.push({
      $or: data.map(item => {
        const searchTerm = {};
        searchTerm[key] = item;
        return searchTerm;
      })
    })
  }

  private unique(value, index, self) {
    return self.indexOf(value) === index;
  }

  private verschiebeSonstigeOption(list: string[]) :string [] {
    list.push(list.splice(list.indexOf("Sonstige"), 1)[0]);
    return list;
  }

  getSumCheckboxes(): number[] {
    return this.sumActiveCheckboxes.concat(this.sumMassnahmen - this.sumActiveCheckboxes.reduce((sum, currentValue) => sum + currentValue, 0));
  }

  public updateMassnahmenCheckboxWatch(key: string, value: boolean) {
    var i = 0;
    switch (key) {
      case 'ISMS':
        i = 0;
        break;
      case 'ORP':
        i = 1;
        break;
      case 'CON':
        i = 2;
        break;
      case 'OPS':
        i = 3;
        break;
      case 'DER':
        i = 4;
        break;
      case 'APP':
        i = 5;
        break;
      case 'SYS':
        i = 6;
        break;
      case 'IND':
        i = 7;
        break;
      case 'NET':
        i = 8;
        break;
      case 'INF':
        i = 9;
        break;
      case 'ICS':
        i = 10;
        break;
      default:
        break;
    }
    value ? this.sumActiveCheckboxes[i]++ : this.sumActiveCheckboxes[i]--;
    this.sumActiveCheckboxesSubject.next(this.sumActiveCheckboxes);
  }

  public getSumMassnahmen(): number[] {
    var isms = 0;
    var orp = 0;
    var con = 0;
    var ops = 0;
    var der = 0;
    var app = 0;
    var sys = 0;
    var ind = 0;
    var net = 0;
    var inf = 0;
    var ics = 0;
    for (const massnahme of this.datasource.value.massnahmen) {
      switch (massnahme.key.split('.')[0]) {
        case 'ISMS':
          isms++;
          break;
        case 'ORP':
          orp++;
          break;
        case 'CON':
          con++;
          break;
        case 'OPS':
          ops++;
          break;
        case 'DER':
          der++;
          break;
        case 'APP':
          app++;
          break;
        case 'SYS':
          sys++;
          break;
        case 'IND':
          ind++;
          break;
        case 'NET':
          net++;
          break;
        case 'INF':
          inf++;
          break;
        case 'ICS':
          ics++;
          break;
        default:
          break;
      }
    }
    this.sumMassnahmen = isms + orp + con + ops + der + app + sys + ind + net + inf + ics;
    return [isms, orp, con, ops, der, app, sys, ind, net, inf, ics, this.sumMassnahmen];
  }

  private initalPieChartRead() {
    var i = -1;
    this.sumActiveCheckboxes = [0,0,0,0,0,0,0,0,0,0,0];
    for (const massnahme of this.datasource.value.massnahmen) {
      if ( massnahme.activeCheckbox == true) {
        switch (massnahme.key.split('.')[0]) {
          case 'ISMS':
            i = 0;
            break;
          case 'ORP':
            i = 1;
            break;
          case 'CON':
            i = 2;
            break;
          case 'OPS':
            i = 3;
            break;
          case 'DER':
            i = 4;
            break;
          case 'APP':
            i = 5;
            break;
          case 'SYS':
            i = 6;
            break;
          case 'IND':
            i = 7;
            break;
          case 'NET':
            i = 8;
            break;
          case 'INF':
            i = 9;
            break;
          case 'ICS':
            i = 10;
            break;
          default:
            break;
        }
        i != -1 ? this.sumActiveCheckboxes[i]++ : null;
      }
    }
    this.sumActiveCheckboxesSubject.next(this.sumActiveCheckboxes);
  }

  public storeFilter() {
    this.appService.sendUserData('CHECKLIST:' + "TEXT" + ':' + 'FILTER', this.filter.text);
    this.appService.sendUserData('CHECKLIST:' + "ROLES" + ':' + 'FILTER', this.filter.rollen.join(', '));
    this.appService.sendUserData('CHECKLIST:' + "THREATS" + ':' + 'FILTER', this.filter.bedrohungen.join(', '));
    this.appService.sendUserData('CHECKLIST:' + "CATEGORY" + ':' + 'FILTER', this.filter.kategorien.join(', '));
    this.appService.sendUserData('CHECKLIST:' + "TYPE" + ':' + 'FILTER', this.filter.anwendungsbereiche.join(', '));
    this.appService.sendUserData('CHECKLIST:' + "EFFORT" + ':' + 'FILTER', this.filter.aufwaende.join(', '));
    this.appService.sendUserData('CHECKLIST:' + "CRITICALITY" + ':' + 'FILTER', this.filter.kritikalitaeten.join(', '));
    this.appService.sendUserData('CHECKLIST:' + "DIN" + ':' + 'FILTER', this.filter.din.join(', '));
    this.appService.sendUserData('CHECKLIST:' + "RELEVANZ" + ':' + 'FILTER', this.filter.relevanz.join(', '));
    this.appService.sendUserData('CHECKLIST:' + "COMMENT" + ':' + 'FILTER', this.filter.comment.join(', '));
    this.appService.sendUserData('CHECKLIST:' + "CHECKBOXACTIVE" + ':' + 'FILTER', this.filter.activeCheckboxString.join(', '));
  }

  public async openCommentComponent(massnahme: MassnahmeUi, typeOfComment: string): Promise<string> {
    const dialogRef: MatDialogRef<ChecklistCommentComponent> = this.dialog.open(ChecklistCommentComponent, {
      disableClose: true,
      data: {
        name: massnahme.data.title,
        key: massnahme.data.key,
        use: typeOfComment,
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    return result;
  }
}

export interface MassnahmeServiceMessage {
  massnahmen: MassnahmeDto[];
  type: MassnahmeMessageType,
  showFiltered: boolean
}

export enum MassnahmeMessageType {
  INIT,
  FILTER
}

export enum MassnahmeGruppierung {
  AUFWAND = "Aufwand",
  BAUSTEIN = "Baustein",
  KATEGORIE = "Kategorie",
  ANWENDUNGSBEREICH = "Anwendungsbereich",
  KRITIKALITÄT = "Kritikalität"
}

export enum MassnahmeKritikalitaet {
  GERING = "Gering",
  MITTEL = "Mittel",
  HOCH = "Hoch",
  SONSTIGE = "Sonstige"
}

export class MassnahmeFilter {
  rollen: string[];
  bedrohungen: string[];
  kritikalitaeten: string[];
  anwendungsbereiche: string[];
  kategorien: string[];
  aufwaende: string[];
  din: string[];
  relevanz: string[];
  comment: string[];
  activeCheckboxString: string[];
  text: string;
  showFiltered = false;
  constructor(
    text?: string,
    rollen?: string[],
    bedrohungen?: string[],
    kritikalitaeten?: string[],
    anwendungsbereiche?: string[],
    kategorien?: string[],
    aufwaende?: string[],
    din?: string[],
    relevanz?: string[],
    comment?: string[],
    activeCheckboxString?: string[]
  ) {
    this.text = text;
    this.rollen = rollen;
    this.bedrohungen = bedrohungen;
    this.kritikalitaeten = kritikalitaeten;
    this.anwendungsbereiche = anwendungsbereiche;
    this.kategorien = kategorien;
    this.aufwaende = aufwaende;
    this.din = din;
    this.relevanz = relevanz;
    this.comment = comment;
    this.activeCheckboxString = activeCheckboxString;
  }
  public hasOptions() {
    return this.rollen?.length > 0 ||
      this.bedrohungen?.length > 0 ||
      this.kritikalitaeten?.length > 0 ||
      this.anwendungsbereiche?.length > 0 ||
      this.kategorien?.length > 0 ||
      this.aufwaende?.length > 0 ||
      this.text?.length > 0 ||
      this.din?.length > 0 ||
      this.relevanz?.length > 0 ||
      this.comment?.length > 0 ||
      this.activeCheckboxString?.length > 0;
  }
  public setText(text: string): MassnahmeFilter {
    this.text = text;
    return this;
  }
  public setShowFiltered(showFiltered: boolean): MassnahmeFilter {
    this.showFiltered = showFiltered;
    return this;
  }
}
