import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { ServicesViewComponent } from '../services-view/services-view.component';
import { Texts } from '../../../texts';

@Component({
  selector: 'app-select-service-erp',
  templateUrl: './select-service-erp.component.html',
  styleUrls: ['./select-service-erp.component.css']
})
export class SelectServiceErpComponent implements OnInit {
  @ViewChild('qcomp') qcomp: ServicesViewComponent;

  public readonly STORAGE_KEY = "SERVICE_ERP";
  public readonly QUESTION = "Welche Services betreiben Sie intern mit Hilfe eines ERP-Systems?";
  public readonly INSTRUCTION = Texts.SELECT_SERVICE + Texts.MORE_INFO;
  public readonly INFO = { title: 'ERP',
  content:
    `
    <p>Bei einem ERP-System handelt es sich um eine Anwendungssoftware bzw. ein IT-System zur Unterst&uuml;tzung der Ressourcenplanung des gesamten Unternehmens (z.B. SAP ERP).<br> Wenn Sie kein ERP-System betreiben, w&auml;hlen Sie alle Services ab. Services, die Sie vorab als nicht vorhanden gekennzeichnet haben, sind hier ausgegraut.</p>
    <p>Klicken Sie auf die Services und w&auml;hlen Sie aus, ob diese in Ihrem Unternehmen mit Hilfe eines ERP-Systems betrieben werden oder nicht. <br>Falls Sie sich unsicher sind, k&ouml;nnen Sie &ldquo;wei&szlig; nicht&rdquo; ausw&auml;hlen. Beachten Sie jedoch, dass das Ergebnis der Plattform ungenauer werden kann, je &ouml;fter Sie &ldquo;wei&szlig; nicht&rdquo; ausw&auml;hlen.</p>
    `
}
  public readonly OPTIONS = {
    'isToggle': false,
    'array': [
      {'value' : '1', 'label': 'ohne ERP System', 'style': 'empty' },
      {'value' : '2', 'label': 'mit ERP-System', 'style': 'full' },
      {'value' : '0', 'label': 'weiß nicht', 'style': 'classic' },
      {'value' : '-1', 'label': 'Obligatorische Sicherheitsanforderungen', 'style': 'warning', 'explanation': 'Diese Maßnahme wird automatisch ausgewählt'}
    ],
  };
  public readonly DEFAULT_OPTION = 0;

  constructor(private app: AppService) { }

  ngOnInit(): void {
  }

  routeAnimationDone() {
  }

  getComponentName(): string {
    return "SelectServiceErpComponent";
  }
}
