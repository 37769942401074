import { KeycloakConfig } from 'keycloak-js';

export const environment = {
  production: false,
  keycloak: {
    url: 'https://art-gsplus-dev.iais.fraunhofer.de/auth',
    realm: 'gsplus',
    clientId: 'gsplus-frontend',
  } as KeycloakConfig,
  apiUrl: 'https://art-gsplus-dev.iais.fraunhofer.de'
};
