import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { ServicesViewComponent } from '../services-view/services-view.component';

@Component({
  selector: 'app-select-service-criticality',
  templateUrl: './select-service-criticality.component.html',
  styleUrls: ['./select-service-criticality.component.css']
})
export class SelectServiceCriticalityComponent implements OnInit {
  @ViewChild('qcomp') qcomp: ServicesViewComponent;

  public readonly STORAGE_KEY = "SERVICES_CRITICALITY";
  public readonly QUESTION = "Bewerten Sie die Kritikalität Ihrer IT-Services.";
  public readonly INSTRUCTION = "Klicken Sie die Services an und wählen Sie deren Kritikalität aus. Hier finden Sie weitere Informationen zur Servicekritikalität."
  public readonly INFO = {
    title:'Servicekritikalität',
  content:`
  <p>Bewerten Sie die Kritikalit&auml;t Ihrer IT-Services. Services, die Sie vorab als nicht vorhanden gekennzeichnet haben, sind hier ausgegraut. Stellen Sie sich dabei f&uuml;r jeden Service die folgenden Fragen:</p>
  <ul>
      <li>Wie kritisch ist es, wenn der entsprechende Service ausf&auml;llt und nicht mehr funktioniert? (=Verf&uuml;gbarkeit)</li>
      <li>Wie kritisch ist es, wenn Informationen oder Daten des entsprechenden Services unbefugt ge&auml;ndert wurden? (=Integrit&auml;t) &nbsp; &nbsp;<br>Beachten Sie, dass eine solche Datenmanipulation nicht immer sofort auff&auml;llt und Sie die manipulierten Daten dadurch m&ouml;glicherweise eine Zeit lang weiterverwenden.</li>
      <li>Wie kritisch ist es, wenn vertrauliche Informationen oder Daten des entsprechenden Services an die &Ouml;ffentlichkeit gelangen bzw. von unbefugten Personen eingesehen werden k&ouml;nnen? (=Vertraulichkeit)</li>
  </ul>
  <p>Falls Sie alle drei Fragen mit &ldquo;unkritisch&rdquo;, &ldquo;wenig kritisch&rdquo; oder &auml;hnlichem beantworten, w&auml;hlen Sie f&uuml;r den entsprechenden Service eine niedrige Kritikalit&auml;t (Gering) aus. Das Risiko ist gering. Sie kommen eine Zeit lang gut ohne den Service aus.</p>
  <p>Falls Sie mindestens eine Frage mit &ldquo;kritisch&rdquo;, &ldquo;nicht unkritisch&rdquo; oder &auml;hnlichem beantworten, w&auml;hlen Sie f&uuml;r den entsprechenden Service eine mittlere Kritikalit&auml;t (Mittel) aus. Das Risiko ist mittel bis betr&auml;chtlich. Sie kommen nur mit erh&ouml;htem Aufwand ohne den Service aus.</p>
  <p>Falls Sie mindestens eine Frage mit &ldquo;sehr kritisch&rdquo;, &ldquo;betriebsgef&auml;hrdend&rdquo;, &ldquo;existenzbedrohend&rdquo; oder &auml;hnlichem beantworten, w&auml;hlen Sie f&uuml;r den entsprechenden Service eine hohe Kritikalit&auml;t (Hoch) aus. Das Risiko ist hoch bis existenzbedrohend. Sie kommen kaum bzw. gar nicht ohne den Service aus, der Betrieb Ihres Unternehmens steht nahezu still.</p>
  <p>Wenn Sie die Maus &uuml;ber die einzelnen Punkte in der Legende (rechts unten) bewegen, erhalten Sie eine kurze Erkl&auml;rung der Auswahlm&ouml;glichkeiten. Anhand Ihrer angegebenen Kritikalit&auml;t wird der sp&auml;tere Ma&szlig;nahmenkatalog priorisiert bzw. geordnet, damit Sie die f&uuml;r Sie wirklich wichtigen Handlungsempfehlungen herausfiltern k&ouml;nnen.</p>
  <p><b>Wählen Sie auch eine Kritikalität für die obligatorischen Services unten rechts!</b></p>
  <p>Klicken Sie auf die Services und wählen Sie aus, wie kritisch diese für Ihr Unternehmen sind. Falls Sie sich unsicher sind, können Sie “weiß nicht” auswählen. Beachten Sie jedoch, dass das Ergebnis der Plattform ungenauer werden kann, je öfter Sie “weiß nicht” auswählen. Wählen Sie auch eine Kritikalität für die obligatorischen Services.</p>`,
  }
  public readonly OPTIONS = {
    'isToggle': false,
    'array': [
      {'value' : '1', 'label': 'Gering', 'explanation': 'Der Ausfall des IT-Services, der Datenverlust UND die Datenmanipulation innerhalb des IT-Services stellen ein geringes Risiko für das Unternehmen dar.', 'style': 'empty' },
      {'value' : '2', 'label': 'Mittel', 'explanation': 'Der Ausfall des IT-Services, der Datenverlust ODER die Datenmanipulation innerhalb des IT-Services stellen maximal ein beträchtliches Risiko für das Unternehmen dar.', 'style': 'half' },
      {'value' : '3', 'label': 'Hoch', 'explanation': 'Der Ausfall des IT-Services, der Datenverlust ODER die Datenmanipulation innerhalb des IT-Services stellen ein existenzbedrohendes Risiko für das Unternehmen dar.', 'style': 'full' },
      {'value' : '0', 'label': 'weiß nicht', 'explanation': '', 'style': 'classic' },
    ],
  };
  public readonly DEFAULT_OPTION = 3;

  constructor(private app: AppService) { }

  ngOnInit(): void {
  }

  routeAnimationDone() {
  }

  getComponentName(): string {
    return "SelectServiceCriticalityComponent";
  }
}
