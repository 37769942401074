import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AnimationCallbacks } from '../animationCallbacks';

@Injectable({
  providedIn: 'root'
})
export class RouteService implements AnimationCallbacks {

  private progress : Subject<boolean> = new Subject();

  public progressStart() {
    this.progress.next(true);
  }

  public progressFinish() {
    this.progress.next(false);
  }

  public get progress$() : Observable<boolean> {
    return this.progress.asObservable();
  }

    //fixme: checken ob wir hier auch <component>.name nutzen können
    //ComponentName <-> path
  private static readonly componentOrder = new Map([
    ["LandingPageComponent", "landing-page"],
    ["BusinessComponent", "business"],
    ["SelectServicesComponent", "services"],
    ["SelectServiceErpComponent", "service-erp"],
    ["SelectServiceProvidersComponent", "service-providers"],
    ["SelectServiceCriticalityComponent", "services-criticality"],
    ["BausteineComponent", "bausteine"],
    ["ChecklistComponent", "checklist"],
    ["ExitPageComponent", "exit-page"],
    ["FeedbackReaderComponent", "sdjasfjeu78dtuoieatgwrutoir56467z3z648et368735z467"],
    ["GlossaryComponent", "glossary"]
  ]);

  private static readonly componentTitles = new Map([
    ["LandingPageComponent", "Landing Page"],
    ["BusinessComponent", "Unternehmen"],
    ["SelectServicesComponent", "Serviceauswahl"],
    ["SelectServiceErpComponent", "ERP-Auswahl"],
    ["SelectServiceProvidersComponent", "Servicebetrieb"],
    ["SelectServiceCriticalityComponent", "Servicekritikalität"],
    ["BausteineComponent", "Bausteine"],
    ["ChecklistComponent","Checklist"],
    ["ExitPageComponent", "Exit Page"],
    ["FeedbackReaderComponent", "Feedbackbox"],
    ["GlossaryComponent", "glossary"]
  ]);

  private static readonly componentOrderIndex = new Map();
  private static readonly componentOrderValues = new Array();

  //FIXME: das soll in Zukunft die ComponentOrderMap ersetzen. Im initialize sollte die dann hieraus gefüllt werden.
  public static readonly componentMenuCategories: Map<string, Array<string>> = new Map([
    ["Fragen", [
      "BusinessComponent"
    ]],
    ["Serviceauswahl", [
      "SelectServicesComponent",
      "SelectServiceErpComponent",
      "SelectServiceProvidersComponent",
      "SelectServiceCriticalityComponent",
    ]],
    ["Ergebnis", [
      "BausteineComponent",
      "ChecklistComponent",
      "ExitPageComponent"
    ]]
  ]);

  private routeAnimationDoneSource = new Subject();
  routeAnimationDone$ = this.routeAnimationDoneSource.asObservable();

  public className: string;

  constructor(private router: Router) { }

  public static initialize() {
    // Reihenfolge der Komponenten in einer Map speichern
    if(this.componentOrderIndex.size == 0) {
      let array = Array.from(this.componentOrder.keys());
      for(let [index, key] of array.entries()) {
        this.componentOrderIndex.set(key, index);
        this.componentOrderValues.push(this.componentOrder.get(key));
      }
    }
  }

  public static getComponentTitle(className: string): string {
    return this.componentTitles.get(className);
  }

  public static getIndex(className: string): number {
    return this.componentOrderIndex.get(className);
  }

  public static getRouteKey(className: string): string {
    return this.componentOrder.get(className);
  }

  routePrev(className: string) {
    let index = RouteService.getIndex(className) - 1;
    if (index < 0) {
      index = RouteService.componentOrder.size - 1;
    }
    this.router.navigateByUrl(RouteService.componentOrderValues[index]);
  }

  routeNext(className: string) {
    let index = RouteService.getIndex(className) + 1;
    if (index >= RouteService.componentOrder.size) {
      index = 0
    }
    this.router.navigateByUrl(RouteService.componentOrderValues[index]);
  }

  routePrevName() {
    let index = RouteService.getIndex(this.className) - 1;
    if (index < 0) {
      index = RouteService.componentOrder.size - 1;
    }
    this.router.navigateByUrl(RouteService.componentOrderValues[index]);
  }

  routeNextName() {
    let index = RouteService.getIndex(this.className) + 1;
    if (index >= RouteService.componentOrder.size) {
      index = 0
    }
    this.router.navigateByUrl(RouteService.componentOrderValues[index]);
  }


  routeAnimationDone() {
    this.routeAnimationDoneSource.next();
  }
}

RouteService.initialize();
