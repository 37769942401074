import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from '../services/app.service';
import { ServiceService } from '../services/service.service';

@Injectable()
export class AppGuard implements CanActivate {

  constructor(
    private appService: AppService,
    private serviceService: ServiceService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> {
    return this.serviceService.services.size > 0
      ? of(true)
      : combineLatest([
        this.appService.loadUUID(),
        this.appService.loadUserData(),
        this.serviceService.loadServices(),
      ]).pipe(map(([a, b]) => a && b));
  }
}