<div class="container">
    <div style="display: flex; justify-content: center">
        <div class="question">
            Herzlichen Dank für die Nutzung des Grundschutz<sup>PLUS</sup> Aktivators.
        </div>
    </div>
    <div class="content">
        <div style="width: 75%; max-width: 1000px; text-align: justify;   margin: auto;">
            <p>Mit der Nutzung und Ihren Anregungen unterst&uuml;tzen Sie das Forschungs- und Transferprojekt Grundschutz<sup style="text-align: justify;">PLUS</sup> Aktivator zur Steigerung des IT-Sicherheitsniveaus kleiner und mittlerer Unternehmen.</p>
            <p>Sollten Sie Fragen haben, k&ouml;nnen Sie uns gerne direkt kontaktieren unter&nbsp;<a href="mailto:gsplus@security4kmu.de" style="text-align: justify;">gsplus@security4kmu.de</a>. Weitere Informationen zu Neuerungen oder den n&auml;chsten Veranstaltungen erhalten Sie bequem &uuml;ber unsere Webseite <a href="https://security4kmu.de/" style="text-align: justify;">Security4KMU</a> oder unseren <a href="https://security4kmu.de/newsletter-anmeldung">Newsletter</a>.</p>
        </div>
    </div>
    <div style="align-self: center; margin: 50px">
      <button mat-button class="button-back" (click)="navigate_prev()" [disabled]="isPrevDisabled()"[attr.aria-label]="'Navigate previous'" style="float:center">Zurück</button>
  </div>
    <app-footer></app-footer>
</div>
