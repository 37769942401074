<div class="container" style="height: 100%">
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input>
  </mat-form-field>

  <table mat-table [dataSource]="dataTable" class="mat-elevation-z8">

    <ng-container matColumnDef="term">
      <th mat-header-cell *matHeaderCellDef> Begriff </th>
      <td mat-cell *matCellDef="let element"> {{element.term}} </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Beschreibung</th>
      <td mat-cell *matCellDef="let element" [innerHTML]="element.description | formatString"></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Keine passenden Daten gefunden: {{input.value}} </td>
    </tr>
  </table>
</div>

<app-footer></app-footer>
