import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserData, UserDataControllerService } from 'build/openapi';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';
import { RouteService } from './route.service';
import { BausteineComponent } from '../components/bausteine/bausteine.component';
import { Router } from '@angular/router';
import { LandingPageComponent } from '../components/landing-page/landing-page.component';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public static readonly KEY_UUID = "USER_UUID";

  private uuidLoaded = new Subject<boolean>();
  private userDataLoaded = new Subject<boolean>();
  private userData: Map<String, UserData> = new Map<string, UserData>();

  constructor(
    private router: Router,
    private authService: AuthService,
    private userDataControllerService: UserDataControllerService) {
  }

  public navigateToHome() {
    this.router.navigate(['home']);
  }

  public navigateToGlossary() {
    this.router.navigate(['glossary']);
  }

  public isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  public loadUUID(): Observable<boolean> {
    if (!this.getLocalUUID() && !this.authService.isLoggedIn()) {
      this.userDataControllerService.generateUuid().subscribe(response => {
        localStorage.setItem(AppService.KEY_UUID, response.uuid);
        this.uuidLoaded.next(true);
      });
      return this.uuidLoaded.asObservable();
    }
    else {
      return of(true);
    }
  }

  public loadUserData(): Observable<boolean> {
    if (this.isLoggedIn()) {
      if (!this.getLocalUUID()) {
        if (this.authService.getUUID()) {
          this.userDataControllerService.getUserData().subscribe((userDataList: UserData[]) => {
            this.handleLoadedUserData(userDataList);
          });
        }
        else {
          this.userDataControllerService.generateUuid().subscribe(response => {
            this.userDataControllerService.getUserDataAnonymous(response.uuid).subscribe((userDataList: UserData[]) => {
              this.handleLoadedUserData(userDataList);
            });
          });
        }
      }
      else if (!this.checkDuplicateUUID()) {
        this.userDataControllerService.getUserDataAnonymous(this.getLocalUUID()).subscribe((userDataList: UserData[]) => {
          this.handleLoadedUserData(userDataList);
        });
        this.sendLocalUUID().subscribe(() => {
          this.removeLocalUUID();
        });
      }
      else {
        return of(false);
      }
    }
    else {
      this.userDataControllerService.getUserDataAnonymous(this.getUUID()).subscribe((userDataList: UserData[]) => {
        this.handleLoadedUserData(userDataList);
      });
    }
    return this.userDataLoaded.asObservable();
  }

  public checkDuplicateUUID(): boolean {
    return this.isLoggedIn() && this.getLocalUUID() && this.authService.getUUID() && this.getLocalUUID() !== this.authService.getUUID();
  }

  public getUUID(): string {
    return this.isLoggedIn() ? this.authService.getUUID() : this.getLocalUUID();
  }

  public sendUUID(uuid: string) {
    return this.userDataControllerService.saveUuid(uuid);
  }

  public sendLocalUUID() {
    return this.userDataControllerService.saveUuid(localStorage.getItem(AppService.KEY_UUID));
  }

  public removeLocalUUID(): string {
    const uuid = localStorage.getItem(AppService.KEY_UUID);
    localStorage.removeItem(AppService.KEY_UUID);
    return uuid;
  }

  public nivigateToLastVisitedStep(): void {
    if (this.userData.size == 0) {
      this.userDataControllerService.getUserData().subscribe((userDataList: UserData[]) => {
        this.handleLoadedUserData(userDataList);
        this.router.navigate([this.getLastVisitedStep()]);
      });
    }
    else {
      this.router.navigate([this.getLastVisitedStep()]);
    }
  }

  public nivigateToLastVisitedStepByUUID(uuid: string): void {
    this.userDataControllerService.getUserDataAnonymous(uuid).subscribe((userDataList: UserData[]) => {
      this.handleLoadedUserData(userDataList);
      this.router.navigate([this.getLastVisitedStep()]);
    });
  }

  public getUserData(key: string): UserData {
    return this.userData.get(key);
  }

  public sendUserData(key: string, value: string) {
    if (this.authService.isLoggedIn()) {
      this.userDataControllerService.saveUserData({ 'requests': [{ 'key': key, 'value': value, }] }).pipe(catchError(this.handleError)).subscribe(() => {
        this.handleSavedUserData(key, value);
      });
    } else {
      this.userDataControllerService.saveUserDataAnonymous({ 'requests': [{ 'uuid': this.getUUID(), 'key': key, 'value': value, }] }).pipe(catchError(this.handleError)).subscribe(() => {
        this.handleSavedUserData(key, value);
      });
    }
  }

  public getLastVisitedStep() {
    if (this.getUserData('CHECKLIST_VISITED') !== undefined) {
      return RouteService.getRouteKey("ChecklistComponent");
    }
    else if (this.getUserData('BAUSTEINE_VISITED') !== undefined) {
      return RouteService.getRouteKey("BausteineComponent");
    }
    else if (this.getUserData('SERVICE_SERVICES_CRITICALITY:sales:b2b') !== undefined) {
      return RouteService.getRouteKey("SelectServiceCriticalityComponent");
    }
    else if (this.getUserData('SERVICE_SERVICE_PROVIDERS:sales:b2b') !== undefined) {
      return RouteService.getRouteKey("SelectServiceProvidersComponent");
    }
    else if (this.getUserData('SERVICE_SERVICE_ERP:sales:b2b') !== undefined) {
      return RouteService.getRouteKey("SelectServiceErpComponent");
    }
    else if (this.getUserData('SERVICE_SERVICES:sales:b2b') !== undefined) {
      return RouteService.getRouteKey("SelectServicesComponent");
    }
    else if ((this.getUserData('QUESTION_NUMBER_QUESTION_SITE_COUNT') !== undefined) && (('QUESTION_BUTTONS_OPTION_INDEX_EMPLOYEE_COUNT') !== undefined) && (this.getUserData('QUESTION_BUTTONS_OPTION_INDEX_TYPE_OF_BUSINESS') !== undefined)) {
      return RouteService.getRouteKey("BusinessComponent");
    }
    else {
      return RouteService.getRouteKey("LandingPageComponent");
    }
  }

  private handleLoadedUserData(userDataList: UserData[]) {
    this.userData = new Map(userDataList.map(userData => {
      return [userData.key, userData];
    }));
    this.userDataLoaded.next(true);
  }

  private handleSavedUserData(key: string, value: string) {
    const userData = this.userData.get(key);
    if (userData) {
      userData.value = value;
    } else {
      this.userData.set(key, {
        key: key,
        value: value
      } as UserData)
    }
  }

  private getLocalUUID(): string {
    return localStorage.getItem(AppService.KEY_UUID);
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
