<ng-container #servicesContainer>
    <span class="label" [style.max-width]="size" [style.background-color]="color">{{label}} <button *ngIf="overarching" mat-button class="button-bordered-small-overarching" [matMenuTriggerFor]="menu">alle</button></span>
</ng-container>

<mat-menu #menu="matMenu">
  <ng-container *ngFor="let option of options.array; index as i">
    <ng-container *ngIf="key == 'SERVICES_CRITICALITY' || (key != 'SERVICES_CRITICALITY'  && i !== options.array.length - 1)">
      <button [matTooltip]="option.explanation" matTooltipPosition="right" [matTooltipClass]="'service-menu'" mat-menu-item (click)="onAllOptionSelected(i)">
        <mat-icon style="--icon-color: black" svgIcon="{{option.style}}-circle"></mat-icon>
        <span>{{option.label}}</span>
    </button>
    <mat-divider *ngIf="(key == 'SERVICES_CRITICALITY' && i !== options.array.length - 1) || (key != 'SERVICES_CRITICALITY' && i !== options.array.length - 2)"  style="margin: 0 7px;"></mat-divider>
    </ng-container>
  </ng-container>
</mat-menu>
