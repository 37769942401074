import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AnimationCallbacks } from '../../../animationCallbacks';
import { Options } from '../../../model/options';
import { AppService } from '../../../services/app.service';
import { BausteinService } from '../../../services/baustein.service';
import { MassnahmeService } from '../../../services/massnahme.service';
import { RouteService } from '../../../services/route.service';
import { ServiceService } from '../../../services/service.service';
@Component({
  selector: 'app-services-view',
  templateUrl: './services-view.component.html',
  styleUrls: ['./services-view.component.css']
})
export class ServicesViewComponent implements OnInit, OnDestroy, AnimationCallbacks {
  @Input() key: string;
  @Input() question: string;
  @Input() instruction: string;
  @Input() options: Options;
  @Input() defaultOption: number;

  constructor(private route:RouteService, public serviceService: ServiceService, private bausteinService: BausteinService, public massnahmeService: MassnahmeService) { }

  routeAnimationDone() {
    this.route.routeAnimationDone();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.bausteinService.ladeBausteineUser();
  }

  isNextDisabled(): boolean {
    return false;
  }

  onAllItOptionSelected(index: number) {
    this.serviceService.onAllItOptionSelected(this.key, index);
  }

  onAllOtOptionSelected(index: number) {
    this.serviceService.onAllOtOptionSelected(this.key, index);
  }
}
