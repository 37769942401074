<div mat-dialog-content style="position: relative; margin-bottom: 0;">
  <mat-form-field appearance="fill">
    <mat-label>Senden Sie Feedback</mat-label>
      <textarea   matInput
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="7"
                  #autosize="cdkTextareaAutosize"
                  [(ngModel)]="feedback">
      </textarea>
  </mat-form-field>
  <button
  mat-raised-button style= "margin-left: -0em;" color="primary" (click)='onSend()' [disabled]="feedback === ''"
  >Feedback senden
</button>
</div>
