import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { UserData } from 'build/openapi';

@Component({
  selector: 'app-checklist-comment',
  templateUrl: './checklist-comment.component.html',
  styleUrls: ['./checklist-comment.component.css']
})
export class ChecklistCommentComponent{

  inputText: string = '';
  formattedCreateDate: string = '';
  formattedUpdateDate: string = '';
  userData: UserData;

  constructor(
    public dialogRef: MatDialogRef<ChecklistCommentComponent>,
    private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.userData = this.appService.getUserData('CHECKLIST:' + this.data.key + ':' + this.data.use.toUpperCase());
    if (this.userData != undefined) {
      const parts = this.userData.value.split(this.data.use.toUpperCase() + ':');
      if (parts.length >= 2) {
        this.inputText = parts[1].trim();
        // this.formattedCreateDate = this.formatDate(new Date(this.userData.createDateTime));
        // this.formattedUpdateDate = this.formatDate(new Date(this.userData.updateDateTime));
      } else {
        this.inputText = '';
        this.formattedCreateDate = "";
        this.formattedUpdateDate = "";
      }
    }
    else {
      this.inputText = "";
      this.formattedCreateDate = "";
      this.formattedUpdateDate = "";
    }
    }

  closeDialog() {
    this.userData ? this.dialogRef.close(this.userData.value) : this.dialogRef.close("empty");
  }

  closeAndReset() {
    this.appService.sendUserData('CHECKLIST:' + this.data.key + ':' + this.data.use.toUpperCase(), "empty");
    this.dialogRef.close("empty");
  }

  closeAndSaveDialog() {
    if (this.inputText != "" ) {
      this.appService.sendUserData('CHECKLIST:' + this.data.key + ':' + this.data.use.toUpperCase(), this.data.use.toUpperCase() + ':' + this.inputText);
      this.dialogRef.close(this.inputText);
    }
    else {
      this.appService.sendUserData('CHECKLIST:' + this.data.key + ':' + this.data.use.toUpperCase(), "empty");
      this.dialogRef.close("empty");
    }


  }

  formatDate(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedYear = `${year}`;
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    return `${formattedDay}.${formattedMonth}.${formattedYear} um ${formattedHours}:${formattedMinutes}`;
  }
}
