<div class="flex-container">
  <div class="flex-subcontainer flex-subcontainer-1">
    <div class="title-container" >
      <h2 class="title" style="color:   #284E74; margin-left: 45%;">Office-IT</h2>
      <button mat-button [disabled]="!(serviceService.ifItSelected() || key == 'SERVICES')" class="button-bordered-small" [matMenuTriggerFor]="menuIT">alle</button>
    </div>

      <div class="line" style="background-color: #284E74; width: 830px"></div>
      <div class="flex-subsubcontainer">
        <div class="flex-item"><app-service-class-view [key]="key" [options]="options" [defaultOption]="defaultOption" color=" #284E74" size="140px" name="sales" class="service-class" style="display: flex; flex-direction: column;"></app-service-class-view></div>
        <div class="flex-item"><app-service-class-view [key]="key" [options]="options" [defaultOption]="defaultOption" color=" #284E74" size="140px" name="logistics" class="service-class" style="display: flex; flex-direction: column;"></app-service-class-view></div>
        <div class="flex-item"><app-service-class-view [key]="key" [options]="options" [defaultOption]="defaultOption" color=" #284E74" size="140px" name="staff" class="service-class" style="display: flex; flex-direction: column;"></app-service-class-view></div>
        <div class="flex-item"><app-service-class-view [key]="key" [options]="options" [defaultOption]="defaultOption" color=" #284E74" size="140px" name="finances" class="service-class" style="display: flex; flex-grow: 1; flex-direction: column;"></app-service-class-view></div>
        <div class="flex-item"><app-service-class-view [key]="key" [options]="options" [defaultOption]="defaultOption" color=" #284E74" size="140px" name="rnd" class="service-class" style="display: flex; flex-direction: column; flex-grow: 1;"></app-service-class-view></div>
      </div>
      <div class="flex-subsubcontainer">
        <div class="flex-item" style="margin-top: 50px;">
          <app-service-class-view [key]="key" [options]="options" [defaultOption]="defaultOption" color=" #284E74" size="820px" name="foundation" class="service-class" style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-start; gap: 0 20px; max-height: 200px; align-items: flex-start;"></app-service-class-view>
        </div>
      </div>
  </div>


  <div class="flex-subcontainer flex-subcontainer-2">

    <div class="title-container" >
      <h2 class="title" style="color:    #ffab40; margin-left: 40%;">Industrial-IT</h2>
      <button mat-button [disabled]="!(serviceService.ifOtSelected() || key == 'SERVICES')" class="button-bordered-small" [matMenuTriggerFor]="menuOT">alle</button>
    </div>
    <div class="line" style="background-color:  #ffab40; width: 660px"></div>
      <div class="flex-subsubcontainer">
        <div class="flex-item"><app-service-class-view [key]="key" [options]="options" [defaultOption]="defaultOption" color=" #ffab40" size="650px" name="ot_production" class="service-class" style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;"></app-service-class-view></div>
        <div class="flex-item"><app-service-class-view [key]="key" [options]="options" [defaultOption]="defaultOption" color=" #ffab40" size="650px" name="ot_foundation" class="service-class" style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-start; gap: 0 20px; max-height: 200px; align-items: flex-start;"></app-service-class-view></div>
      </div>

    <div class="title-container" >
      <h2 class="title" style="color:#263238;">Obligatorische Sicherheitsanforderungen</h2>
    </div>
    <div class="line" style="background-color: #263238; width:660px"></div>
    <div class="flex-subsubcontainer">
            <div class="flex-item"><app-service-class-view [key]="key" [options]="options" [defaultOption]="defaultOption" color="#263238" size="650px" name="it_overarching" class="service-class" style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-start; gap: 0 20px; max-height: 200px; align-items: flex-start;"></app-service-class-view></div>
            <div class="flex-item"><app-service-class-view [key]="key" [options]="options" [defaultOption]="defaultOption" color="#263238" size="650px" name="ot_overarching" class="service-class" style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-start; gap: 0 20px; max-height: 200px; align-items: flex-start;"></app-service-class-view></div>
    </div>
  </div>
</div>
<div class="flex-subcontainer-legend">
  <div *ngFor="let option of options.array; index as i" class="legend-option" matTooltipPosition="right" [matTooltip]="option.explanation" [matTooltipClass]="'legend'">
    <span>
      <mat-icon *ngIf="option.style !== 'warning'" style="--icon-color: #263238" svgIcon="{{option.style}}-circle"></mat-icon>
      <mat-icon *ngIf="option.style === 'warning'" style="--icon-color: #263238" svgIcon="warning"></mat-icon>
    </span>
    {{option.label}}
  </div>
</div>

<!-- allOptions Button -->

<mat-menu #menuIT="matMenu">
  <ng-container *ngFor="let option of options.array; index as i">
    <ng-container *ngIf="key == 'SERVICES_CRITICALITY' || (key != 'SERVICES_CRITICALITY'  && i !== options.array.length - 1)">
      <button [matTooltip]="option.explanation" matTooltipPosition="right" [matTooltipClass]="'service-menu'" mat-menu-item (click)="onAllItOptionSelected(i)">
        <mat-icon style="--icon-color: black" svgIcon="{{option.style}}-circle"></mat-icon>
        <span>{{option.label}}</span>
    </button>
    <mat-divider *ngIf="(key == 'SERVICES_CRITICALITY' && i !== options.array.length - 1) || (key != 'SERVICES_CRITICALITY' && i !== options.array.length - 2)"  style="margin: 0 7px;"></mat-divider>
    </ng-container>
  </ng-container>
</mat-menu>

<mat-menu #menuOT="matMenu">
  <ng-container *ngFor="let option of options.array; index as i">
    <ng-container *ngIf="key == 'SERVICES_CRITICALITY' || (key != 'SERVICES_CRITICALITY'  && i !== options.array.length - 1)">
      <button [matTooltip]="option.explanation" matTooltipPosition="right" [matTooltipClass]="'service-menu'" mat-menu-item (click)="onAllOtOptionSelected(i)">
        <mat-icon style="--icon-color: black" svgIcon="{{option.style}}-circle"></mat-icon>
        <span>{{option.label}}</span>
    </button>
    <mat-divider *ngIf="(key == 'SERVICES_CRITICALITY' && i !== options.array.length - 1) || (key != 'SERVICES_CRITICALITY' && i !== options.array.length - 2)"  style="margin: 0 7px;"></mat-divider>
    </ng-container>
  </ng-container>
</mat-menu>
