import { MassnahmeDto } from 'build/openapi';

interface ChecklistElementUi {
    active: boolean;
}

export interface MassnahmeUi extends ChecklistElementUi {
    data: MassnahmeDto;
}

export interface GruppensegmentUi extends ChecklistElementUi {
    key: string;
    name: string;
    relevant: boolean,
    anzahlDerMassnahmen: number,
    massnahmenGruppen: Map<string, MassnahmenGruppeUi>;
}

export interface MassnahmenGruppeUi extends ChecklistElementUi {
    name: string;
    anzahlDerMassnahmen: number,
    massnahmen: Map<string, MassnahmeUi>;
}

export interface GruppensegmentExpansionStatus {
    segmentExpanded: boolean,
    gruppenExpanded: boolean,
    gruppen: Map<string, boolean>;
}


export class BausteinUi{
    key: string;
    title: string;
    order: number;

    constructor(
        key: string,
        title: string,
        order: number,
        ){
            this.key= key;
            this.title = title;
            this.order = order;
        }
}

