import { Injectable } from '@angular/core';
import { Service, ServiceGroup } from 'build/openapi';
import { Observable, Subject, of } from 'rxjs';
import { OptionSelection } from '../model/option-selection';
import { ServiceControllerService } from 'build/openapi/api/serviceController.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  private servicesLoaded = new Subject<boolean>();

  private allItSelectionSource = new Subject<OptionSelection>();
  allItSelectionSource$ = this.allItSelectionSource.asObservable();

  private allOverarchingItSelectionSource = new Subject<OptionSelection>();
  allOverarchingItSelectionSource$ = this.allOverarchingItSelectionSource.asObservable();

  private allOtSelectionSource = new Subject<OptionSelection>();
  allOtSelectionSource$ = this.allOtSelectionSource.asObservable();

  private allOverarchingOtSelectionSource = new Subject<OptionSelection>();
  allOverarchingOtSelectionSource$ = this.allOverarchingOtSelectionSource.asObservable();

  public readonly services = new Map<string, Map<string, Service>>();
  public readonly serviceGroups = new Map<string, ServiceGroup>();

  private selectedOptionsIt: string[] = [];
  private selectedOptionsSourceIt = new Subject<string[]>();
  selectedOptionsIt$ = this.selectedOptionsSourceIt.asObservable();

  private selectedOptionsOt: string[] = [];
  private selectedOptionsSourceOt = new Subject<string[]>();
  selectedOptionsOt$ = this.selectedOptionsSourceOt.asObservable();

  constructor(
    private serviceControllerService: ServiceControllerService) {
  }

  public loadServices(): Observable<boolean> {
    this.serviceControllerService.services().subscribe((data) => {
      data.forEach(service => {
        if (!this.services.has(service.group.key)) {
          this.services.set(service.group.key, new Map());
          this.serviceGroups.set(service.group.key, service.group);
        }
        this.services.get(service.group.key).set(service.key, service);
      });
      this.servicesLoaded.next(true);
    });
    return this.servicesLoaded.asObservable();
  }

  public onAllItOptionSelected(key: string, index: number) {
    this.allItSelectionSource.next({ 'key': key, 'index': index });
  }

  public onAllOtOptionSelected(key: string, index: number) {
    this.allOtSelectionSource.next({ 'key': key, 'index': index });
  }

  public onAllOverarchingItOptionSelected(key: string, index: number) {
    this.allOverarchingItSelectionSource.next({ 'key': key, 'index': index });
  }

  public onAllOverarchingOtOptionSelected(key: string, index: number) {
    this.allOverarchingOtSelectionSource.next({ 'key': key, 'index': index });
  }

  ifItSelected(): boolean {
    return !(this.selectedOptionsIt.length === 0);
  }

  ifOtSelected(): boolean {
    return !(this.selectedOptionsOt.length === 0);
  }

  updateSelectedOptionsIt(key: string, isSelected: boolean) {
    if (isSelected) {
      this.selectedOptionsIt.push(key);
    } else {
      this.selectedOptionsIt = this.selectedOptionsIt.filter((item) => item !== key);
    }
    this.selectedOptionsSourceIt.next(this.selectedOptionsIt);
  }

  updateSelectedOptionsOt(key: string, isSelected: boolean) {
    if (isSelected) {
      this.selectedOptionsOt.push(key);
    } else {
      this.selectedOptionsOt = this.selectedOptionsOt.filter((item) => item !== key);
    }
    this.selectedOptionsSourceOt.next(this.selectedOptionsOt);
  }

}
