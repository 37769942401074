import { Component, OnInit } from '@angular/core';
import { RouteService } from '../../services/route.service';

@Component({
  selector: 'app-exit-page',
  templateUrl: './exit-page.component.html',
  styleUrls: ['./exit-page.component.css']
})
export class ExitPageComponent implements OnInit {
  key: string = 'EXIT_PAGE';

  constructor(private route: RouteService) { }

  ngOnInit(): void {}

  isPrevDisabled(): boolean {
    return false;
  }

  isNextDisabled(): boolean {
    return true;
  }

  routeAnimationDone() {
    this.route.routeAnimationDone();
  }

  navigate_prev(): void {
    this.route.routePrev("ExitPageComponent");
  }

  navigate_next(): void {
    this.route.routeNext("ExitPageComponent");
  }

}
