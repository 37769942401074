<div class="container" style="height: 100%">
    <div style="display: flex; justify-content: center; align-items: center;">
        <div class="content" style="flex: 0 0 auto; width: 95%;">
            <app-base-question [question]="'Bausteine des BSI IT-Grundschutz-Kompendium'"[instruction]="INSTRUCTION" [info]="INFO"></app-base-question>
        </div>
        <mat-slide-toggle [(ngModel)]="showAllBausteine" (change)="showAllBausteineChanged($event)">Alle Bausteine</mat-slide-toggle>
    </div>
    <div class="content" style="display: flex; gap: 10px; flex-wrap: wrap;">
        <mat-card appearance="outlined" *ngFor="let key of ['ISMS']" class="baustein-card" style="width: 236px">
            <span class="block-title">{{getBlockTitle(key)}}</span>
            <div class="block">
                <app-baustein *ngFor="let baustein of getBausteine(key)" class="baustein" [baustein]="baustein" [primaryColor]="getPrimaryColor(key)" [secondaryColor]="getSecondaryColor(key)"></app-baustein>
            </div>
        </mat-card>
        <mat-card appearance="outlined" *ngFor="let key of ['ORP']" class="baustein-card" style="width: 477px">
            <span class="block-title">{{getBlockTitle(key)}}</span>
            <div class="block">
                <app-baustein *ngFor="let baustein of getBausteine(key)" class="baustein" [baustein]="baustein" [primaryColor]="getPrimaryColor(key)" [secondaryColor]="getSecondaryColor(key)"></app-baustein>
            </div>
        </mat-card>
        <mat-card appearance="outlined" *ngFor="let key of ['CON']" class="baustein-card" style="width: 477px">
            <span class="block-title">{{getBlockTitle(key)}}</span>
            <div class="block">
                <app-baustein *ngFor="let baustein of getBausteine(key)" class="baustein" [baustein]="baustein" [primaryColor]="getPrimaryColor(key)" [secondaryColor]="getSecondaryColor(key)"></app-baustein>
            </div>
        </mat-card>
        <mat-card appearance="outlined" *ngFor="let key of ['DER']" class="baustein-card" style="width: 236px">
            <span class="block-title">{{getBlockTitle(key)}}</span>
            <div  class="block">
                <app-baustein *ngFor="let baustein of getBausteine(key)" class="baustein" [baustein]="baustein" [primaryColor]="getPrimaryColor(key)" [secondaryColor]="getSecondaryColor(key)"></app-baustein>
            </div>
        </mat-card>
        <mat-card appearance="outlined" *ngFor="let key of ['SYS']" class="baustein-card-high" style="width: 480px">
            <span class="block-title">{{getBlockTitle(key)}}</span>
            <div class="block">
                <app-baustein *ngFor="let baustein of getBausteine(key)" class="baustein" [baustein]="baustein" [primaryColor]="getPrimaryColor(key)" [secondaryColor]="getSecondaryColor(key)"></app-baustein>
            </div>
        </mat-card>
        <mat-card appearance="outlined" *ngFor="let key of ['APP']" class="baustein-card-high" style="width: 480px">
            <span class="block-title">{{getBlockTitle(key)}}</span>
            <div class="block">
                <app-baustein *ngFor="let baustein of getBausteine(key)" class="baustein" [baustein]="baustein" [primaryColor]="getPrimaryColor(key)" [secondaryColor]="getSecondaryColor(key)"></app-baustein>
            </div>
        </mat-card>
        <mat-card appearance="outlined" *ngFor="let key of ['NET']" class="baustein-card" style="width: 480px">
            <span class="block-title">{{getBlockTitle(key)}}</span>
            <div class="block">
                <app-baustein *ngFor="let baustein of getBausteine(key)" class="baustein" [baustein]="baustein" [primaryColor]="getPrimaryColor(key)" [secondaryColor]="getSecondaryColor(key)"></app-baustein>
            </div>
        </mat-card>
        <mat-card appearance="outlined" *ngFor="let key of ['OPS']" class="baustein-card" style="width:880px">
            <span class="block-title">{{getBlockTitle(key)}}</span>
            <div class="block">
                <app-baustein *ngFor="let baustein of getBausteine(key)" class="baustein" [baustein]="baustein" [primaryColor]="getPrimaryColor(key)" [secondaryColor]="getSecondaryColor(key)"></app-baustein>
            </div>
        </mat-card>
        <mat-card appearance="outlined" *ngFor="let key of ['INF']" class="baustein-card" style="width:572px">
            <span class="block-title">{{getBlockTitle(key)}}</span>
            <div class="block">
                <app-baustein *ngFor="let baustein of getBausteine(key)" class="baustein" [baustein]="baustein" [primaryColor]="getPrimaryColor(key)" [secondaryColor]="getSecondaryColor(key)"></app-baustein>
            </div>
        </mat-card>
        <mat-card appearance="outlined" *ngFor="let key of ['IND']" class="baustein-card" style="width:1460px">
            <span class="block-title">{{getBlockTitle(key)}}</span>
            <div class="block">
                <app-baustein *ngFor="let baustein of getBausteine(key)" class="baustein" [baustein]="baustein" [primaryColor]="getPrimaryColor(key)" [secondaryColor]="getSecondaryColor(key)"></app-baustein>
                <app-baustein *ngFor="let baustein of getBausteine('ICS')" class="baustein" [baustein]="baustein" [primaryColor]="getPrimaryColor(key)" [secondaryColor]="getSecondaryColor(key)"></app-baustein>
            </div>
        </mat-card>
    </div>
</div>
<app-footer></app-footer>
