import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { MassnahmeUi } from '../../../model/checklist-ui';
import { MassnahmeService } from '../../../services/massnahme.service';
import { AppService } from 'src/app/services/app.service';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-checklist-item',
  templateUrl: './checklist-item.component.html',
  styleUrls: ['./checklist-item.component.css']
})
export class ChecklistItemComponent implements OnInit {

  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger;

  criticality: string;
  effort: string;
  spec: string = "";
  tooltipRoles: string;
  tooltipEffort: string;
  tooltipCriticality: string;
  tooltipThreats: string;
  tooltipCategory: string;

  commentText: string = "Kommentar hinzufügen";

  notRelevant: boolean = false;
  isLogged: boolean = false;

  menuTrigger: boolean = false;

  private criticalityMap = {
    Gering: { class: "criticality-low", tooltip: "Die Kritikalität dieser Maßnahme ist gering." },
    Mittel: { class: "criticality-medium", tooltip: "Die Kritikalität dieser Maßnahme ist mittel." },
    Hoch: { class: "criticality-high", tooltip: "Die Kritikalität dieser Maßnahme ist hoch." },
  };

  private effortMap = {
    Gering: { class: "effort-low", tooltip: "Der Umsetzungsaufwand dieser Maßnahme ist gering." },
    Mittel: { class: "effort-medium", tooltip: "Der Umsetzungsaufwand dieser Maßnahme ist mittel." },
    Hoch: { class: "effort-high", tooltip: "Der Umsetzungsaufwand dieser Maßnahme ist hoch." },
  };

  @Input() massnahme: MassnahmeUi;
  constructor(private massnahmeService: MassnahmeService, private appService: AppService) {
    this.isLogged = this.isLoggedIn();
  }

  ngOnInit(): void {
    this.loadValue();
    this.setIcons();
    this.spec = this.massnahme.data.din !== "empty" ? "Diese Maßnahme ist Teil der:\n" : "";
    if (this.spec != "") {
      this.massnahme.data.din.split(",").forEach((din) => {
        this.spec += `\u2022 ${din}\n`;
      });
    }
    this.tooltipRoles = "Diese Maßnahme liegt in der Verantwortung von:\n";
    this.massnahme.data.roles.forEach((roles) => {
      this.tooltipRoles += `\u2022 ${roles}\n`;
    });
    this.tooltipThreats = "Bedrohungen in Verbindung mit dieser Maßnahme:\n";
    this.massnahme.data.threats.forEach((threat) => {
      this.tooltipThreats += `\u2022 ${threat}\n`;
    });
    this.tooltipCategory = "Diese Maßnahme gehört zu der Kategorie: \n\u2022 " + this.massnahme.data.category;
    this.updateMassnahme();
  }

  // onCheckboxChange(checked: boolean) {
  //   this.massnahme.data.activeCheckbox = checked;
  //   this.massnahme.data.activeCheckboxString = this.massnahme.data.activeCheckbox.toString();
  //   if (this.isLoggedIn()) {
  //     var tmp = this.massnahme.data.comment;
  //     this.massnahmeService.openCommentComponent(this.massnahme, "comment").then(comment => {
  //       this.massnahme.data.comment = comment;
  //       if (tmp != this.massnahme.data.comment)  {
  //         this.appService.sendUserData('CHECKLIST:' + this.massnahme.data.key + ':' + 'CHECKBOX', `${checked}`);
  //         this.massnahmeService.updateMassnahmenCheckboxWatch(this.massnahme.data.key.split('.')[0], this.massnahme.data.activeCheckbox);
  //         this.massnahme.data.comment = comment;
  //       }
  //       else {
  //         this.massnahme.data.activeCheckbox = !checked;
  //         this.massnahme.data.activeCheckboxString = this.massnahme.data.activeCheckbox.toString();
  //       }
  //     });
  //   }
  //   else {this.massnahmeService.updateMassnahmenCheckboxWatch(this.massnahme.data.key.split('.')[0], this.massnahme.data.activeCheckbox);}
  //   this.updateMassnahme();
  // }

  onCheckboxChange(checked: boolean) {
    this.massnahme.data.activeCheckbox = checked;
    this.massnahme.data.activeCheckboxString = this.massnahme.data.activeCheckbox.toString();
    if (this.isLoggedIn()) {
      this.appService.sendUserData('CHECKLIST:' + this.massnahme.data.key + ':' + 'CHECKBOX', `${checked}`);
    }
    this.massnahmeService.updateMassnahmenCheckboxWatch(this.massnahme.data.key.split('.')[0], this.massnahme.data.activeCheckbox);
    this.updateMassnahme();
  }

  get_comment(use: string) {
    this.massnahmeService.openCommentComponent(this.massnahme, use).then(comment => {
      if(use == "relevant") {
        this.massnahme.data.relevanz = comment;
        this.notRelevant = this.massnahme.data.relevanz == "empty" ? false : true;
      }
      if(use == "comment") {
        this.massnahme.data.comment = comment;
        this.commentText = this.massnahme.data.comment == "empty" ? "Kommentar hinzufügen" : "Kommentar bearbeiten";
      }
    });
    this.updateMassnahme();
  }

  get_comment_afer_checkbox_change() {
    this.massnahmeService.openCommentComponent(this.massnahme, 'comment').then(comment => {
    this.massnahme.data.comment = comment;
    this.commentText = this.massnahme.data.comment == "empty" ? "Kommentar hinzufügen" : "Kommentar bearbeiten";
    });
    this.updateMassnahme();
  }

  updateMassnahme() {
    this.massnahmeService.updateMassnahme(this.massnahme.data);
  }

  isLoggedIn() : boolean {
    return this.appService.isLoggedIn();
  }

  setIcons() {
    this.criticality = this.criticalityMap[this.massnahme.data.criticality]?.class || "criticality-low";
    this.tooltipCriticality = this.criticalityMap[this.massnahme.data.criticality]?.tooltip || "Die Kritikalität dieser Maßnahme ist gering.";
    this.effort = this.effortMap[this.massnahme.data.effort]?.class || "effort-low";
    this.tooltipEffort = this.effortMap[this.massnahme.data.effort]?.tooltip || "Der Umsetzungsaufwand dieser Maßnahme ist gering.";
  }

  loadValue() {
    if (this.isLogged) {
      this.notRelevant = this.massnahme.data.relevanz != undefined ? this.massnahme.data.relevanz != "empty" ? true : false : false ;
    }
  }

  mouseLeave() {
      this.menu.closeMenu();
  }
}
