import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { Subject} from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { MassnahmeDto } from '../../../../../build/openapi';
import { MassnahmeGruppierung, MassnahmeMessageType, MassnahmeService, MassnahmeServiceMessage } from '../../../services/massnahme.service';
import { GruppensegmentExpansionStatus, GruppensegmentUi, MassnahmenGruppeUi, MassnahmeUi } from '../../../model/checklist-ui';

@Component({
  selector: 'app-checklist-massnahmen',
  templateUrl: './checklist-massnahmen.component.html',
  styleUrls: ['./checklist-massnahmen.component.css']
})
export class ChecklistMassnahmenComponent implements OnInit, OnDestroy {

  notifier$ = new Subject();
  gruppierung = MassnahmeGruppierung.BAUSTEIN;
  segmente = new Map<string, GruppensegmentUi>();
  segmenteList: GruppensegmentUi[];

  multiExpansionStatus = new Map<string, GruppensegmentExpansionStatus>();

  showFiltered = false;

  @ViewChild('mep') mep: MatExpansionPanel;
  @ViewChild('segmentPanel') segmentPanel: MatExpansionPanel;


  constructor(private massnahmeService: MassnahmeService) {}

  ngOnInit() {
    this.createSegmente();
    this.massnahmeService.datasource$.pipe(takeUntil(this.notifier$)).subscribe((message : MassnahmeServiceMessage) => {
      this.showFiltered = message.showFiltered;
      if (message.type == MassnahmeMessageType.INIT) {
        this.createMapping(message.massnahmen);
      }
      else if (message.type == MassnahmeMessageType.FILTER) {
        this.updateMapping(message.massnahmen);
      }
      this.segmenteList = this.getSegmente();
    });
    this.massnahmeService.gruppierung$.pipe(takeUntil(this.notifier$)).subscribe((gruppierung : MassnahmeGruppierung) => {
      this.gruppierung = gruppierung;
      this.createSegmente();
      this.massnahmeService.applyExistingFilter();
    });
    this.massnahmeService.alleMassnahmen();
  }

  ngOnDestroy(): void {
    this.notifier$.next();
    this.notifier$.complete();
  }

  toggleSegmentPanel(): void {
    this.segmentPanel.toggle();
  }

  allSubPanelsOpened(segment: any): boolean {
    return segment.subItems.every(subItem => subItem.isPanelExpanded);
  }

  toggleAllSubPanels(segment: any): void {
    const shouldOpen = !this.allSubPanelsOpened(segment);
    segment.subItems.forEach(subItem => (subItem.isPanelExpanded = shouldOpen));
  }

  getSegmentName(segment: GruppensegmentUi){
    return segment.key === segment.name ? segment.key : `${segment.name} - ${segment.key}`;
  }

  private createSegmente() {
    this.segmente.clear();
    switch(this.gruppierung) {
      case MassnahmeGruppierung.BAUSTEIN:
        var bausteine = this.massnahmeService.bausteine.sort((a, b) => (a.order < b.order ? -1 : 1));
        bausteine.forEach(baustein => {
          this.addSegmentUi(baustein.key, baustein.title);
        });
        break;
      case MassnahmeGruppierung.AUFWAND:
          this.massnahmeService.aufwaende.forEach(a => {
            this.addSegmentUi(a, a);
          });
          break;
      case MassnahmeGruppierung.KATEGORIE:
        this.massnahmeService.kategorien.forEach(k => {
          this.addSegmentUi(k, k);
        });
        break;
      case MassnahmeGruppierung.ANWENDUNGSBEREICH:
        this.massnahmeService.anwendungsbereiche.forEach(a => {
          this.addSegmentUi(a, a);
        });
        break;
      case MassnahmeGruppierung.KRITIKALITÄT:
        this.massnahmeService.kritikalitaeten.forEach(k => {
          this.addSegmentUi(k, k);
        });
        break;
    }
  }

  private createMapping(massnahmen: MassnahmeDto[]) {
    this.data(massnahmen).forEach(mn => {
      const segmentUi = this.getSegment(mn);
      if (segmentUi) {
        segmentUi.relevant = true;
        segmentUi.active = true;
        let massnahmenGruppe = segmentUi.massnahmenGruppen.get(mn.title);
        if (!massnahmenGruppe) {
          massnahmenGruppe = {
            name: mn.title,
            massnahmen: new Map<string, MassnahmeUi>(),
            active: true
          } as MassnahmenGruppeUi;
          segmentUi.massnahmenGruppen.set(mn.title, massnahmenGruppe);
        }
        else {
          massnahmenGruppe.active = true;
        }
        massnahmenGruppe.massnahmen.set(mn.key, {
          data: mn,
          active: true
        } as MassnahmeUi);
      }
    });
    this.setAnzahlDerMassnahmen();
  }

  private data(massnahmen: MassnahmeDto[]) : MassnahmeDto[] {
    switch(this.gruppierung) {
      case MassnahmeGruppierung.BAUSTEIN:
        return massnahmen.filter(m => m.bausteinKey);
      case MassnahmeGruppierung.AUFWAND:
        return massnahmen.filter(m => m.effort);
      case MassnahmeGruppierung.KATEGORIE:
        return massnahmen.filter(m => m.category);
      case MassnahmeGruppierung.ANWENDUNGSBEREICH:
        return massnahmen.filter(m => m.type);
      case MassnahmeGruppierung.KRITIKALITÄT:
        return massnahmen.filter(m => m.criticality);
    }
  }

  private getSegment(massnahme: MassnahmeDto) : GruppensegmentUi {
    switch(this.gruppierung) {
      case MassnahmeGruppierung.BAUSTEIN:
        return this.segmente.get(massnahme.bausteinKey);
      case MassnahmeGruppierung.AUFWAND:
        return this.segmente.get(massnahme.effort);
      case MassnahmeGruppierung.KATEGORIE:
        return this.segmente.get(massnahme.category);
      case MassnahmeGruppierung.ANWENDUNGSBEREICH:
        return this.segmente.get(massnahme.type);
      case MassnahmeGruppierung.KRITIKALITÄT:
        return this.segmente.get(massnahme.criticality);
    }
  }

  private updateMapping(massnahmen: MassnahmeDto[]) {
    this.segmente.forEach(bausteinSegment => {
      if (bausteinSegment.relevant) {
        let found = false;
        bausteinSegment.massnahmenGruppen.forEach(gruppe => {
          if (this.updateMassnahmenGruppe(gruppe, massnahmen) && !found) {
            found = true;
          }
        });
        bausteinSegment.active = found;
      }
    });
    this.setAnzahlDerMassnahmen();
  }

  private updateMassnahmenGruppe(massnahmenGruppe: MassnahmenGruppeUi, massnahmen: MassnahmeDto[]) : boolean {
    let found = false;
    massnahmenGruppe.massnahmen.forEach(massnahme => {
      const index = massnahmen.findIndex((m) => m.key == massnahme.data.key);
      massnahme.active = index >= 0;
      if (massnahme.active) {
        found = true;
        massnahmen.splice(index, 1)
      }
    });
    massnahmenGruppe.active = found;
    return massnahmenGruppe.active ? true : false;
  }

  setAnzahlDerMassnahmen() {
    this.segmente.forEach(segment => {
      if (segment.relevant) {
        segment.anzahlDerMassnahmen = 0;
        segment.massnahmenGruppen.forEach(gruppe => segment.anzahlDerMassnahmen = segment.anzahlDerMassnahmen + this.setAnzahlDerMassnahmenGruppe(gruppe));
      }
    })
  }

  setAnzahlDerMassnahmenGruppe(gruppe: MassnahmenGruppeUi) : number {
    gruppe.anzahlDerMassnahmen = gruppe.active ? Array.from(gruppe.massnahmen.values()).map(m => m.active ? 1 : 0).reduce((a, b) => (a+b), 0 ) : 0;
    return gruppe.anzahlDerMassnahmen;
  }


  getSegmente(): GruppensegmentUi[] {
    return Array.from(this.segmente.values()).reduce((list: GruppensegmentUi[], segmentUi)  => {
      if(segmentUi.relevant && (this.showFiltered || segmentUi.active)) {
          list.push(segmentUi)
      }
      return list;
    }, []);
  }

  addSegmentUi(key: string, name: string) {
    this.segmente.set(key, {
      key: key,
      name: name,
      relevant: false,
      active: false,
      anzahlDerMassnahmen: 0,
      massnahmenGruppen: new Map<string, MassnahmenGruppeUi>()
    })
  }

  getMassnahmenGruppenBySegment(key: string): MassnahmenGruppeUi[] {
    if (this.showFiltered) {
      return Array.from(this.segmente.get(key).massnahmenGruppen.values());
    }
    else {
      return Array.from(this.segmente.get(key).massnahmenGruppen.values()).filter(g => g.active);
    }
  }

  processMultiExpansion(event: MouseEvent, accordion: MatAccordion, segmentKey: string) {
    const status = this.multiExpansionStatus.get(accordion.id);
    if (status === undefined) {
      this.multiExpansionStatus.set(accordion.id, {
        segmentExpanded: true,
        gruppenExpanded: true,
        gruppen: new Map()
      });
      accordion.openAll();
    }
    else if (!status.segmentExpanded) {
      status.gruppenExpanded = true;
      accordion.openAll();
    }
    else {
      status.gruppenExpanded = !status.gruppenExpanded;
      if (status.gruppenExpanded) {
        event.stopPropagation();
        accordion.openAll();
      }
      else {
        event.stopPropagation();
        accordion.closeAll();
      }
    }
  }

  openSegmentPanel(accordion: MatAccordion) {
    const status = this.multiExpansionStatus.get(accordion.id);
    if (status === undefined) {
      this.multiExpansionStatus.set(accordion.id, {
        segmentExpanded: true,
        gruppenExpanded: false,
        gruppen: new Map()
      });
    }
    else {
      status.segmentExpanded = true;
    }
  }

  closeSegmentPanel(accordion: MatAccordion) {
    this.multiExpansionStatus.get(accordion.id).segmentExpanded = false;
  }

  openMassnahmenGruppePanel(panel: MatExpansionPanel) {
    this.processMassnahmenGruppeExpansionStatus(panel.accordion.id, panel.id, true);
  }

  closeMassnahmenGruppePanel(panel: MatExpansionPanel) {
    this.processMassnahmenGruppeExpansionStatus(panel.accordion.id, panel.id, false);
  }

  processMassnahmenGruppeExpansionStatus(accordionId: string, panelId: string, value: boolean) {
    const status = this.multiExpansionStatus.get(accordionId);
    status.gruppen.set(panelId, value);
    status.gruppenExpanded = !Array.from(status.gruppen.values()).every((elem) => elem === false);
  }

  expanded(accordion: MatAccordion) {
    return this.multiExpansionStatus.get(accordion.id)?.segmentExpanded && this.multiExpansionStatus.get(accordion.id)?.gruppenExpanded;
  }

  segmentPanelOpened(accordion: MatAccordion) : boolean {
    return this.multiExpansionStatus.get(accordion.id)?.segmentExpanded;
  }

  gruppePanelOpened(panel: MatExpansionPanel): boolean {
    return panel.expanded;
  }

}
