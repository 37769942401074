import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppService } from '../../services/app.service';
import { RouteService } from '../../services/route.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent implements OnInit {

  showProgress = false;
  selectedIndex = 0;
  navItemOrder: Array<number> = new Array();
  routeLinks: Array<string> = new Array();
  routeTitles: Array<string> = new Array();

  private currentRoute = "";

  public textButton: string = "Weiter";

  constructor(
    private router: Router,
    private routeService: RouteService,
    private appService: AppService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.routeService.progressStart();
      }
      else if (event instanceof NavigationEnd) {
        this.currentRoute = event.url.split('/')[1];
        this.routeService.progressFinish();
      }
    });
    let i = 0;
    for(let [group,items] of Array.from(RouteService.componentMenuCategories)) {
      for(let item of items) {
        this.navItemOrder.push(i++);
        this.routeLinks.push(RouteService.getRouteKey(item))
        this.routeTitles.push(RouteService.getComponentTitle(item))
      }
    }
    this.routeService.progress$.subscribe(value => {
      this.showProgress = value;
    });
    this.navItemOrder.pop();
  }

  ngAfterViewChecked() {
    this.selectedIndex = this.getSelectedIndex();
    this.cdRef.detectChanges();
  }

  toDisplay(): boolean {
    if (this.currentRoute === 'exit-page') {
      return false;
    }
    return this.routeLinks.includes(this.currentRoute);
  }

  getSelectedIndex() : number {
    const index = this.routeLinks.findIndex(l => l == this.currentRoute);
    return index ? index : 0;
  }

  getFontClass(index : number) {
    if (this.isActive(index)) {
      return "font-active";
    }
    else if (this.isProcessed(index)) {
      return "font-processed";
    }
    else {
      return "font-inactive";
    }
  }

  isActive(index : number) : boolean {
    return index == this.routeLinks.findIndex(l => l == this.currentRoute);
  }

  isInactive(index : number) : boolean {
    return index === 0 ? false : index > this.routeLinks.findIndex(l => l == this.currentRoute) && !this.isProcessed(index - 1);
  }

  isProcessed(index : number) : boolean {
    if (index === 0) {
      const typeOfBusinessDefined = this.appService.getUserData('QUESTION_BUTTONS_OPTION_INDEX_TYPE_OF_BUSINESS') !== undefined;
      const employeeCountDefined = this.appService.getUserData('QUESTION_BUTTONS_OPTION_INDEX_EMPLOYEE_COUNT') !== undefined;
      const siteCountDefined = this.appService.getUserData('QUESTION_NUMBER_QUESTION_SITE_COUNT') !== undefined;
      return typeOfBusinessDefined && employeeCountDefined && siteCountDefined;
    }
    else if (index === 1) {
      return this.appService.getUserData('SERVICE_SERVICES:sales:b2b') !== undefined;
    }
    else if (index === 2) {
      return this.appService.getUserData('SERVICE_SERVICE_ERP:sales:b2b') !== undefined;
    }
    else if (index === 3) {
      return this.appService.getUserData('SERVICE_SERVICE_PROVIDERS:sales:b2b') !== undefined;
    }
    else if (index === 4) {
      return this.appService.getUserData('SERVICE_SERVICES_CRITICALITY:sales:b2b') !== undefined;
    }
    else if (index === 5) {
      return this.appService.getUserData('BAUSTEINE_VISITED') !== undefined;
    }
    else if (index === 6) {
      return this.appService.getUserData('CHECKLIST_VISITED') !== undefined;
    }
    return index < this.routeLinks.findIndex(l => l == this.currentRoute);
  }

  navigateTo(index: number) {
    if (this.currentRoute !== this.routeLinks[index]) {
      this.router.navigate([this.routeLinks[index]]);
    }
  }

  getComponentTitle(index: number): string {
    if (this.routeService.className === "ChecklistComponent") {
      this.textButton = "Fertig";
    }
    else {
      this.textButton = "Weiter";
    }
    return this.routeTitles[index];
  }

  isPrevDisabled(): boolean {
    return false;
  }

  isNextDisabled(): boolean {
    return false;
  }

  navigate_prev(): void {
    this.routeService.routePrevName();
  }

  navigate_next(): void {
    this.routeService.routeNextName();
  }

}
